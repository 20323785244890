import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import AttachFile from '@material-ui/icons/AttachFile';
import { storage } from '../../firebase';
import { incluirAnexoDocumento, getDocumentos } from '../../actions/documento';
import { withSnackbar } from 'notistack';
import LinearProgress from '@material-ui/core/LinearProgress';

const styles = (theme) => ({
  input: {
    display: 'none',
  },
  progress: {
    width: '100%',
    marginTop: theme.spacing(2),
  },
  previewContainer: {
    marginTop: theme.spacing(2),
    textAlign: 'center',
  },
  previewImage: {
    maxWidth: '100%',
    maxHeight: 200,
  },
  fileInfo: {
    marginTop: theme.spacing(1),
    fontSize: '0.9rem',
    color: theme.palette.text.secondary,
  },
});

const AnexoDocumento = ({
  classes,
  id,
  anexo: initialAnexo,
  email,
  incluirAnexoDocumento,
  handleDelete,
  enqueueSnackbar,
}) => {
  const [anexo, setAnexo] = useState(initialAnexo || {});
  const [uploadProgress, setUploadProgress] = useState(0);

  useEffect(() => {
    if (initialAnexo) {
      setAnexo(initialAnexo);
    }
  }, [initialAnexo]);

  const handleCapture = (event) => {
    const file = event.target.files[0];
    const uploadTask = storage.uploadAnexoDocumento(`${id}/${file.name}`, file);
    uploadTask.on(
      'state_changed',
      (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setUploadProgress(progress);
      },
      (error) => {
        console.error('Erro ao fazer upload do documento:', error);
        enqueueSnackbar('Erro ao fazer upload do documento', { variant: 'error' });
      },
      () => {
        uploadTask.snapshot.ref
          .getMetadata()
          .then(({ fullPath }) => {
            const newAnexo = { nome: file.name, url: fullPath };
            incluirAnexoDocumento(id, newAnexo, email).then(() => {
              setUploadProgress(0);
              setAnexo(newAnexo);
              enqueueSnackbar('Documento incluído com sucesso', {
                variant: 'success',
              });
              getDocumentos();
            });
          })
          .catch((error) => {
            console.error('Erro ao salvar anexo no banco:', error);
            enqueueSnackbar('Erro ao salvar anexo no banco', { variant: 'error' });
          });
      }
    );
  };

  const handleDeleteAnexo = () => {
    setAnexo(null);
    handleDelete(anexo);
  };

  if (anexo && anexo.url) {
    return (
      <Grid item container sm={12} spacing={2}>
        <Chip
          size="small"
          key={anexo.url}
          onDelete={handleDeleteAnexo}
          onClick={() => storage.downloadAnexo(anexo.url)}
          label={anexo.nome}
          clickable
          variant="outlined"
        />
      </Grid>
    );
  } else {
    return (
      <>
        {uploadProgress > 0 && (
          <LinearProgress
            variant="determinate"
            value={uploadProgress}
            className={classes.progress}
          />
        )}
        <Grid item container sm={12} spacing={2}>
          <input
            accept="application/pdf"
            className={classes.input}
            id={id}
            onChange={handleCapture}
            type="file"
          />
          <label htmlFor={id}>
            <Button color="primary" component="span" size="small" style={{ marginRight: 8 }}>
              <AttachFile /> Anexar Documento
            </Button>
          </label>
        </Grid>
      </>
    );
  }
};

export default connect(null, { incluirAnexoDocumento, getDocumentos })(
  withStyles(styles, { withTheme: true })(withSnackbar(AnexoDocumento))
);
