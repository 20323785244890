import React, { useState } from 'react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';
import { withStyles } from '@material-ui/core/styles';
import SindilegisBar from '../commons/SindilegisBar';
import styles from './styles';
import Typography from '@material-ui/core/Typography';
import ButtonComponent from '../commons/ButtonComponent';
import Grid from '@material-ui/core/Grid';

const PowerBi = () => {
  const URL_BASE =
    'https://app.powerbi.com/reportEmbed?reportId=3362f1ff-af90-4a68-ad0a-e8e83422700c&autoAuth=true&ctid=5eeed76e-89f0-43c3-b0ee-4c697d152371';
  const URL_PAINEL_FILIADOS =
    'https://app.powerbi.com/reportEmbed?reportId=8dee7b89-6d18-4ec1-b14b-99246a3c5b2c&autoAuth=true&ctid=5eeed76e-89f0-43c3-b0ee-4c697d152371';
  const [url, setUrl] = useState(URL_BASE);
  const [selected, setSelected] = useState('base');

  return (
    <React.Fragment>
      <SindilegisBar />
      <Container maxWidth="xl" style={{ paddingTop: 20, margin: 'auto' }}>
        <Typography variant="h4" style={{ marginBottom: 20 }}>
          Power Bi
        </Typography>
        <Grid container>
          <ButtonComponent
            onClick={() => {
              setUrl(URL_BASE);
              setSelected('base');
            }}
            variant={selected === 'base' ? 'insert' : 'default'}>
            Base
          </ButtonComponent>
          <ButtonComponent
            onClick={() => {
              setUrl(URL_PAINEL_FILIADOS);
              setSelected('painel');
            }}
            variant={selected === 'painel' ? 'insert' : 'default'}>
            Painel de Filiados
          </ButtonComponent>
        </Grid>
        <iframe
          title="Bi Sindilegis"
          width="100%"
          height="800"
          src={url}
          style={{ border: 'none' }}
          allowFullScreen="true"
        />
      </Container>
    </React.Fragment>
  );
};

PowerBi.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default compose(withStyles(styles))(PowerBi);
