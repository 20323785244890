export const tipoObjeto = ['Contrato', 'Documento'];

const gerarNumeroContrato = (quantidadeContratosExistentes) => {
  const ano = new Date().getFullYear();
  const numeroSequencial = quantidadeContratosExistentes + 1;
  return `${numeroSequencial.toString().padStart(4, '0')}/${ano}`;
};

export default gerarNumeroContrato;

export const isContratoEncerrado = (situacao) => situacao === 'Encerrado';
