import React, { useEffect } from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect, useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import InputText from '../../commons/InputText';
import { useSnackbar } from 'notistack';
import {
  getObjetoProcesso,
  criarObjetoProcesso,
  saveObjetoProcesso,
} from '../../../actions/objetoProcesso';
import { compose } from 'recompose';

const ObjetoProcessoForm = ({
  handleSubmit,
  pristine,
  submitting,
  reset,
  open,
  handleClose,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch()
  const { objetoProcessoId } = useSelector((state) => state.objetoProcesso)

  useEffect(() => {
    if (objetoProcessoId) {
      dispatch(getObjetoProcesso(objetoProcessoId));
    }
  }, [objetoProcessoId, dispatch]);

  const save = (objetoProcesso) => {
    if (objetoProcessoId) {
      dispatch(saveObjetoProcesso(objetoProcessoId, objetoProcesso, () => successHandler()));
    } else {
      dispatch(criarObjetoProcesso(objetoProcesso, () => successHandler(true)));
    }
  };

  const successHandler = (reload) => {
    enqueueSnackbar('Objeto de processo salvo com sucesso!', { variant: 'success' });
    reset('objetoProcesso');
    handleClose(reload);
  };

  return (
    <Dialog open={open} onClose={() => handleClose(null)} fullWidth maxWidth="sm">
      <DialogTitle>Objeto de Processo</DialogTitle>
      <form onSubmit={handleSubmit(save)}>
        <DialogContent>
          <Grid container spacing={2} alignItems="center">
            <Grid item sm={12}>
              <Field name="descricao" component={InputText} label="Descrição" multiline={true} />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => { reset(); handleClose(); }} color="default">
            Cancelar
          </Button>
          <Button variant="contained" type="submit" disabled={pristine || submitting} color="primary">
            Salvar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

ObjetoProcessoForm.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  objetoProcessoId: PropTypes.string,
  getObjetoProcesso: PropTypes.func.isRequired,
  criarObjetoProcesso: PropTypes.func.isRequired,
  saveObjetoProcesso: PropTypes.func.isRequired,
};

const validate = (values) => {
  const errors = {};
  const requiredFields = ['descricao'];
  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = 'Obrigatório';
    }
  });
  return errors;
};

const mapStateToProps = (state) => {
  const { objetoProcesso } = state.objetoProcesso;
  return { initialValues: objetoProcesso, }
};

export default compose(connect(mapStateToProps, {
}))(
  reduxForm({
    form: 'objetoProcesso',
    validate,
    enableReinitialize: true,
  })(ObjetoProcessoForm)
);
