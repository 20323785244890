import React from 'react';
import { withSnackbar } from 'notistack';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import SindilegisBar from '../commons/SindilegisBar';
import RangeDatePicker from '../commons/RangeDatePicker';
import styles from './styles';
import Loading from '../commons/Loading';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import {
  getAtendimentos,
  getEscritoriosAdvocacia,
  getProcessos,
  criarRelacionamentoFiliadosProcessos,
  adicionarIDsParaEventos,
  SITUACAO_ATENDIMENTO_CANCELADO,
  SITUACAO_ATENDIMENTO_CONCLUIDO,
  SITUACAO_ATENDIMENTO_PENDENTE_CONCLUSAO,
  SITUACAO_ATENDIMENTO_PENDENTE_REALIZACAO,
  SITUACAO_PROCESSO_AGUARDANDO_MANIFESTACAO_SINDILEGIS,
  SITUACAO_PROCESSO_EM_ANDAMENTO,
  SITUACAO_PROCESSO_ENCERRADO,
} from '../../actions/juridico';
import Button from '@material-ui/core/Button';
import * as routes from '../../constants/routes';
import * as roles from '../../constants/roles';
import _ from 'lodash';
import AtendimentoForm from './Atendimento/atendimento';
import ProcessoAdministrativoForm from './Processo/processoAdministrativo';
import ProcessoJudicialForm from './Processo/processoJudicial';
import ProcedimentoForm from './Processo/procedimento';
import { getFiliados } from '../../actions/filiado';
import { getUser } from '../../actions/user';
import { formataData, isDataAnterior } from '../commons/util';
import { getDataFinalJuridico, getDataInicialJuridico } from '../Financial/util';
import { doCreateFiliadoProcesso } from '../../firebase/db';
import MenuButton from '../commons/MenuButton';
import { paletaAuxiliarIntenso as COLORS } from '../commons/SindilegisColors';
import CustomPieChart from './Processo/CustomPieChart';

class HomeJuridico extends React.Component {
  state = {
    sumarioProcessos: [],
    atendimentoOpen: false,
    processoJudicialOpen: false,
    processoAdministrativoOpen: false,
    procedimentoOpen: false,
    atendimentosFromDate: getDataInicialJuridico(),
    atendimentosToDate: getDataFinalJuridico(),
    processosFromDate: getDataInicialJuridico(),
    processosToDate: getDataFinalJuridico(),
    filteredProcessos: [],
    filteredAtendimentos: [],
    relacionamentoFiliadosCriado: false,
  };

  handleAtendimentoClickOpen = () => {
    this.setState({ atendimentoOpen: true });
  };

  handleAtendimentoClose = (reload) => {
    const { getAtendimentos } = this.props;
    this.setState({ atendimentoOpen: false });
    if (reload) {
      getAtendimentos();
    }
  };

  componentDidMount() {
    const {
      getAtendimentos,
      getProcessos,
      getFiliados,
      getEscritoriosAdvocacia,
      authUser,
      getUser,
    } = this.props;
    const authUserId = authUser?.uid;
    getAtendimentos();
    getProcessos();
    getFiliados();
    getEscritoriosAdvocacia();
    if (authUser) {
      getUser(authUserId);
    }
  }

  async componentDidUpdate(prevProps) {
    const { processos, atendimentos, adicionarIDsParaEventos } = this.props;
    const { relacionamentoFiliadosCriado } = this.state;

    if (
      !relacionamentoFiliadosCriado &&
      (prevProps.processos !== processos || prevProps.atendimentos !== atendimentos)
    ) {
      this.atualizarDatas();
      adicionarIDsParaEventos(processos);
      criarRelacionamentoFiliadosProcessos(doCreateFiliadoProcesso);
      this.setState({ relacionamentoFiliadosCriado: true });
    }
  }

  atualizarDatas() {
    const storedAtendimentosFromDate = sessionStorage.getItem('atendimentosFromDate');
    const storedAtendimentosToDate = sessionStorage.getItem('atendimentosToDate');
    const storedProcessosFromDate = sessionStorage.getItem('processosFromDate');
    const storedProcessosToDate = sessionStorage.getItem('processosToDate');

    const atendimentosFromDate = storedAtendimentosFromDate
      ? new Date(storedAtendimentosFromDate)
      : getDataInicialJuridico();
    const atendimentosToDate = storedAtendimentosToDate
      ? new Date(storedAtendimentosToDate)
      : getDataFinalJuridico();
    const processosFromDate = storedProcessosFromDate
      ? new Date(storedProcessosFromDate)
      : getDataInicialJuridico();
    const processosToDate = storedProcessosToDate
      ? new Date(storedProcessosToDate)
      : getDataFinalJuridico();

    this.setState({
      atendimentosFromDate,
      atendimentosToDate,
      processosFromDate,
      processosToDate,
    });

    this.filterAtendimentos(atendimentosFromDate.getTime(), atendimentosToDate.getTime());
    this.filterProcessos(processosFromDate.getTime(), processosToDate.getTime());
  }

  handleDateChangeAtendimentos(date) {
    const { enqueueSnackbar } = this.props;
    if (date.fromDate > date.toDate) {
      enqueueSnackbar('A data final não pode ser anterior à data inicial!', {
        variant: 'error',
      });
      return;
    }
    this.setState(
      {
        atendimentosFromDate: date.fromDate,
        atendimentosToDate: date.toDate,
      },
      () => {
        sessionStorage.setItem('atendimentosFromDate', date.fromDate.toISOString());
        sessionStorage.setItem('atendimentosToDate', date.toDate.toISOString());
      }
    );
    this.filterAtendimentos(date.fromDate, date.toDate);
  }

  handleDateChangeProcessos(date) {
    if (date.fromDate > date.toDate) {
      this.props.enqueueSnackbar('A data final não pode ser anterior à data inicial!', {
        variant: 'error',
      });
      return;
    }
    this.setState(
      {
        processosFromDate: date.fromDate,
        processosToDate: date.toDate,
      },
      () => {
        sessionStorage.setItem('processosFromDate', date.fromDate.toISOString());
        sessionStorage.setItem('processosToDate', date.toDate.toISOString());
      }
    );
    this.filterProcessos(date.fromDate.getTime(), date.toDate.getTime());
  }

  filterAtendimentos(atendimentosFromDate, atendimentosToDate) {
    const { atendimentos } = this.props;
    if (atendimentosFromDate && atendimentosToDate) {
      let filteredAtendimentos = [];

      Object.keys(atendimentos).forEach((escritorio) => {
        filteredAtendimentos.push(
          ...Object.values(atendimentos[escritorio]).filter((a) => {
            const dataAtendimento = new Date(a.data);
            return dataAtendimento >= atendimentosFromDate && dataAtendimento <= atendimentosToDate;
          })
        );
      });
      this.setState({ filteredAtendimentos });
    } else {
      return null;
    }
  }

  filterProcessos(processosFromDate, processosToDate) {
    const { processos } = this.props;
    if (processosFromDate && processosToDate) {
      const filteredProcessos = Object.values(processos).filter(
        (p) => processosFromDate <= p.dataProtocolo && processosToDate >= p.dataProtocolo
      );
      this.setState({ filteredProcessos });
    } else {
      return null;
    }
  }

  prepareAtendimentosPieData() {
    const { authUser } = this.props;
    const todosProcessos =
      _.includes(authUser.roles, roles.JURIDICO) ||
      _.includes(authUser.roles, roles.VISUALIZADOR_JURIDICO);
    const sumario = this.calcularSumarioAtendimentos(todosProcessos);
    return sumario.map((item) => ({
      name: item.descricao,
      value: item.quantidade,
    }));
  }

  prepareProcessosPieData() {
    const { authUser } = this.props;
    const todosProcessos =
      _.includes(authUser.roles, roles.JURIDICO) ||
      _.includes(authUser.roles, roles.VISUALIZADOR_JURIDICO);
    const sumario = this.calcularSumarioProcessos(todosProcessos);
    return sumario.map((item) => ({
      name: item.descricao,
      value: item.quantidade,
    }));
  }

  render() {
    const { classes, loading, authUser, location, isEscritorio } = this.props;
    const {
      atendimentosFromDate,
      atendimentosToDate,
      processosFromDate,
      processosToDate,
      atendimentoOpen,
      processoAdministrativoOpen,
      processoJudicialOpen,
      procedimentoOpen,
    } = this.state;
    const isEscritorioJuridico = _.includes(authUser.roles, roles.ESCRITORIO_JURIDICO);
    const isAuth = Boolean(authUser);

    const BUTTON_ROUTES = [
      {
        label: 'Gerenciar objetos de processo',
        route: routes.OBJETO_PROCESSO,
        condition: isEscritorioJuridico,
      },
      { label: 'Gerenciar processos', route: routes.JURIDICO_PROCESSOS },
      { label: 'Relatório de processos', route: routes.JURIDICO_PROCESSOS_RELATORIO },
    ].filter((button) => button.condition !== true);

    const CONDITIONAL_BUTTON = [
      { label: 'Escritórios', route: routes.ESCRITORIOS, condition: isAuth },
    ];

    const todosProcessos =
      _.includes(authUser.roles, roles.JURIDICO) ||
      _.includes(authUser.roles, roles.VISUALIZADOR_JURIDICO);
    const isVisualizador = _.includes(authUser.roles, roles.VISUALIZADOR_JURIDICO);
    const isJuridico = _.includes(authUser.roles, roles.JURIDICO);

    const atendimentosPieData = this.prepareAtendimentosPieData();
    const filteredAtendimentosPieData = atendimentosPieData.filter((item) => item.value > 0);
    const processosPieData = this.prepareProcessosPieData();
    const filteredProcessosPieData = processosPieData.filter((item) => item.value > 0);
    const title = isEscritorio ? 'Escritório Jurídico' : 'Jurídico';
    const isButtonDisabled = !isJuridico && isEscritorioJuridico;
    const showAtendimentosChart = filteredAtendimentosPieData.length > 0;
    const showProcessosChart = filteredProcessosPieData.length > 0;

    return (
      <div className={classes.root}>
        <SindilegisBar />
        <Loading loading={loading} />
        {loading ? null : (
          <Container maxWidth="xl">
            {!isVisualizador && (
              <MenuButton
                buttonsRoutes={BUTTON_ROUTES}
                location={location}
                additionalButtons={CONDITIONAL_BUTTON}
              />
            )}
            <Card
              className={classes.paper}
              elevation={1}
              hidden={loading}
              style={{ paddingBottom: 20 }}>
              <CardHeader title={title} />
              <Grid container>
                <Grid item md={6}>
                  <Card className={classes.paper} variant="outlined">
                    <CardHeader title="Atendimentos"></CardHeader>
                    <CardContent>
                      <Box width="100%" display="flex" justifyContent="flex-end" mb="8px">
                        {!isVisualizador && (
                          <Button
                            variant="outlined"
                            size="small"
                            color="primary"
                            disabled={isButtonDisabled}
                            onClick={() => this.handleAtendimentoClickOpen()}>
                            Agendar atendimento
                          </Button>
                        )}
                      </Box>
                      <Grid
                        container
                        item
                        md={9}
                        spacing={9}
                        justifyContent="center"
                        alignItems="center">
                        <RangeDatePicker
                          fromDate={atendimentosFromDate}
                          toDate={atendimentosToDate}
                          onChange={(date) =>
                            this.handleDateChangeAtendimentos(date)
                          }></RangeDatePicker>
                      </Grid>
                      <Grid container direction="column" alignItems="center">
                        {this.calcularSumarioAtendimentos(todosProcessos).map((item) =>
                          this.renderSumario(item)
                        )}
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
                <CustomPieChart
                  title="Gráfico de Atendimentos"
                  className={classes.paper}
                  hidden={loading}
                  data={filteredAtendimentosPieData}
                  colors={COLORS}
                  showChart={showAtendimentosChart}
                  containerHeight="520px"
                />
                <Grid item md={6}>
                  <Card className={classes.paper} variant="outlined" hidden={loading}>
                    <CardHeader
                      title="Processos/Procedimentos"
                      style={{ textAlign: 'flex-start' }}
                    />
                    <CardContent>
                      {isVisualizador ? null : (
                        <Box width="100%" display="flex" justifyContent="flex-end" mb="8px">
                          <Button
                            variant="outlined"
                            size="small"
                            color="primary"
                            onClick={() => this.setState({ processoJudicialOpen: true })}>
                            Novo processo judicial
                          </Button>
                          <Button
                            variant="outlined"
                            size="small"
                            style={{ margin: '0 4px' }}
                            color="primary"
                            onClick={() => this.setState({ processoAdministrativoOpen: true })}>
                            Novo processo administrativo
                          </Button>
                          <Button
                            variant="outlined"
                            size="small"
                            color="primary"
                            onClick={() => this.setState({ procedimentoOpen: true })}>
                            Novo procedimento
                          </Button>
                        </Box>
                      )}
                      <Grid
                        container
                        item
                        md={9}
                        spacing={9}
                        justifyContent="center"
                        alignItems="center">
                        <RangeDatePicker
                          fromDate={processosFromDate}
                          toDate={processosToDate}
                          onChange={(date) =>
                            this.handleDateChangeProcessos(date)
                          }></RangeDatePicker>
                      </Grid>
                      <Grid container direction="column" alignItems="center">
                        {this.calcularSumarioProcessos(todosProcessos).map((item) =>
                          this.renderSumario(item)
                        )}
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
                <CustomPieChart
                  className={classes.paper}
                  hidden={loading}
                  title="Gráfico de Processos"
                  data={filteredProcessosPieData}
                  colors={COLORS}
                  showChart={showProcessosChart}
                  containerHeight="300px"
                />
              </Grid>
            </Card>
          </Container>
        )}
        <AtendimentoForm
          open={atendimentoOpen}
          handleClose={this.handleAtendimentoClose}
          acao="Agendar"
          authUser={authUser}
        />
        <ProcessoAdministrativoForm
          open={processoAdministrativoOpen}
          handleClose={this.handleProcessoAdministrativoClose}
          authUser={authUser}
        />
        <ProcessoJudicialForm
          open={processoJudicialOpen}
          handleClose={this.handleProcessoJudicialClose}
          authUser={authUser}
        />
        <ProcedimentoForm
          open={procedimentoOpen}
          handleClose={this.handleProcedimentoClose}
          authUser={authUser}
        />
      </div>
    );
  }

  handleGetProcessos = () => {
    const { getProcessos } = this.props;
    getProcessos();
  };

  handleProcessoAdministrativoClose = (reload) => {
    this.setState({ processoAdministrativoOpen: false });
    if (reload) {
      this.handleGetProcessos();
    }
  };

  handleProcessoJudicialClose = (reload) => {
    this.setState({ processoJudicialOpen: false });
    if (reload) {
      this.handleGetProcessos();
    }
  };

  handleProcedimentoClose = (reload) => {
    this.setState({ procedimentoOpen: false });
    if (reload) {
      this.handleGetProcessos();
    }
  };

  renderSumario(item) {
    const { classes, history } = this.props;

    return (
      <Grid
        key={item.descricao}
        container
        alignItems="center"
        className={classes.botoesAtendimentos}
        onClick={() => history.push(item.rota)}>
        <Grid item xs={8} md={8} className={classes.ajustePadding}>
          <Typography variant="body1">{item.descricao}</Typography>
        </Grid>
        <Grid item xs={4} md={4} className={classes.ajustePadding}>
          <Typography variant="h5" align="right" color="primary" justfy="right">
            {item.quantidade}
          </Typography>
        </Grid>
      </Grid>
    );
  }

  calcularSumarioAtendimentos(todosAtendimentos) {
    const { user } = this.props;
    const { filteredAtendimentos, atendimentosFromDate, atendimentosToDate } = this.state;

    let agendadosParaHoje = 0;
    let todosAgendados = 0;
    let dataVencida = 0;
    let pendentes = 0;
    let cancelados = 0;
    let concluidos = 0;
    const today = new Date();
    const dataInicial = formataData(atendimentosFromDate);
    const dataFinal = formataData(atendimentosToDate);
    const compareDates = dataInicial === dataFinal;

    filteredAtendimentos
      .filter((key) => todosAtendimentos || user?.escritorios?.includes(key))
      .forEach((a) => {
        if (a.situacao === SITUACAO_ATENDIMENTO_PENDENTE_REALIZACAO) {
          todosAgendados++;

          if (compareDates) {
            dataFinal === formataData(a.data) && agendadosParaHoje++;
          }
          isDataAnterior(new Date(a.data), today) && dataVencida++;
        }

        a.situacao === SITUACAO_ATENDIMENTO_PENDENTE_CONCLUSAO && pendentes++;
        a.situacao === SITUACAO_ATENDIMENTO_CANCELADO && cancelados++;
        a.situacao === SITUACAO_ATENDIMENTO_CONCLUIDO && concluidos++;
      });

    const situacoes = [
      {
        titulo: 'Agendados para hoje',
        quantidade: agendadosParaHoje,
        situacao: SITUACAO_ATENDIMENTO_PENDENTE_REALIZACAO,
        extraParam: compareDates ? '&hoje=true' : null,
      },
      {
        titulo: 'Todos os agendados',
        quantidade: todosAgendados,
        situacao: SITUACAO_ATENDIMENTO_PENDENTE_REALIZACAO,
      },
      {
        titulo: 'Data de realização vencida',
        quantidade: dataVencida,
        situacao: SITUACAO_ATENDIMENTO_PENDENTE_REALIZACAO,
        extraParam: '&vencidos=true',
      },
      {
        titulo: 'Pendentes de conclusão',
        quantidade: pendentes,
        situacao: SITUACAO_ATENDIMENTO_PENDENTE_CONCLUSAO,
      },
      {
        titulo: 'Cancelados',
        quantidade: cancelados,
        situacao: SITUACAO_ATENDIMENTO_CANCELADO,
      },
      {
        titulo: 'Concluídos',
        quantidade: concluidos,
        situacao: SITUACAO_ATENDIMENTO_CONCLUIDO,
      },
    ];

    return situacoes.map(({ titulo, quantidade, situacao, extraParam = '' }) =>
      Sumario(
        titulo,
        quantidade,
        this.getRotaAtendimentos(
          situacao,
          atendimentosFromDate.getTime(),
          atendimentosToDate.getTime()
        ) + extraParam
      )
    );
  }

  getRotaAtendimentos(
    situacao,
    atendimentosFromDate,
    atendimentosToDate,
    vencidos = false,
    hoje = false
  ) {
    let url = `juridico/atendimentos?situacao=${situacao}&fromDate=${atendimentosFromDate}&toDate=${atendimentosToDate}`;
    if (vencidos) {
      url += '&vencidos=true';
    }
    if (hoje) {
      url += `&hoje=true`;
    }
    return url;
  }

  calcularSumarioProcessos(todosProcessos) {
    const { user, processos } = this.props;
    const { filteredProcessos, processosFromDate, processosToDate } = this.state;

    if (Object.values(processos).length === 0) {
      return [];
    }

    const filtrosBase = filteredProcessos.filter(
      (p) => todosProcessos || user.escritorios?.includes(p.escritorio.id)
    );

    const situacoes = [
      {
        descricao: 'Em andamento',
        situacao: SITUACAO_PROCESSO_EM_ANDAMENTO,
      },
      {
        descricao: 'Aguardando manifestação do Sindilegis',
        situacao: SITUACAO_PROCESSO_AGUARDANDO_MANIFESTACAO_SINDILEGIS,
      },
      {
        descricao: 'Encerrados',
        situacao: SITUACAO_PROCESSO_ENCERRADO,
      },
    ];

    return situacoes.map(({ descricao, situacao }) => {
      const quantidade = filtrosBase.filter((p) => p.situacao === situacao).length;
      const rota = this.getRotaProcessos(
        situacao,
        processosFromDate.getTime(),
        processosToDate.getTime()
      );
      return { descricao, quantidade, rota };
    });
  }

  getRotaProcessos(situacao, processosFromDate, processosToDate) {
    return `juridico/processos?situacao=${situacao}&fromDate=${processosFromDate}&toDate=${processosToDate}`;
  }
}

function Sumario(descricao, quantidade, rota) {
  return { descricao, quantidade, rota };
}

const mapStateToProps = ({
  juridico: { atendimentos, processos, loading, escritoriosAdvocacia },
  userState: { user },
}) => ({
  atendimentos,
  loading,
  processos,
  escritoriosAdvocacia,
  user,
});

HomeJuridico.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    getAtendimentos,
    getEscritoriosAdvocacia,
    getFiliados,
    getProcessos,
    getUser,
    criarRelacionamentoFiliadosProcessos,
    adicionarIDsParaEventos,
  })
)(withRouter(withSnackbar(HomeJuridico)));
