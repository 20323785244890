import _ from 'lodash';
import {
  CONTRATOS_SET,
  CONTRATO_SET,
  CONTRATO_ID_SET,
  CONTRATOS_REQUEST,
  CONTRATO_UPDATED,
  HISTORICO_SET,
} from './types';
import { db } from '../firebase';
import gerarNumeroContrato, {
  isContratoEncerrado,
} from '../components/GestaoDeContratos/components/common/utils';

export const saveContrato = (id, data, callback) => {
  return (dispatch) => {
    return db.doSaveContrato(id, data).then(() => {
      dispatch({ type: CONTRATO_UPDATED, payload: { id, data } });
      callback && callback();
    });
  };
};

export const criarContrato = (data, callback) => {
  return (dispatch) => {
    db.onceGetContratosAtivos()
      .then((contratosAtivos) => {
        const quantidadeContratos = contratosAtivos.length;

        data.situacao = 'Vigente';
        data.data_carregamento = new Date().getTime();
        data.numero_contrato = gerarNumeroContrato(quantidadeContratos);

        db.doCreateContrato(data)
          .then((ref) => {
            callback && callback(ref.key, quantidadeContratos);
          })
          .catch((error) => {
            console.error('Erro ao criar contrato:', error);
          });
      })
      .catch((error) => {
        console.error('Erro ao buscar contratos ativos:', error);
      });
  };
};

export const getContratos = () => {
  return (dispatch) => {
    dispatch({ type: CONTRATOS_REQUEST });
    db.onceGetContratos().then((snapshot) => {
      let contratos = snapshot.val();
      if (contratos) {
        Object.keys(contratos).map((id) => {
          contratos[id].id = id;
          contratos[id].vigente = contratos[id].data_fim >= new Date().getTime() ? 'Sim' : 'Não';
          return contratos[id];
        });
        contratos = _.orderBy(
          _.values(contratos),
          [(contrato) => contrato.nome.toLowerCase()],
          ['asc']
        );
      } else {
        contratos = [];
      }
      dispatch({ type: CONTRATOS_SET, contratos });
    });
  };
};

export const getContrato = (id) => {
  return (dispatch) => {
    db.onceGetContrato(id).then((snapshot) => {
      const contrato = snapshot.val();
      dispatch({ type: CONTRATO_SET, contrato });
    });
  };
};

export const setContrato = (contrato) => (dispatch) => dispatch({ type: CONTRATO_SET, contrato });

export const setContratoId = (contratoId) => (dispatch) =>
  dispatch({ type: CONTRATO_ID_SET, contratoId });

export const incluirAnexoContrato = (anexo, contratoId) => {
  return async () => {
    try {
      return await db.doIncluirAnexoContrato(anexo, contratoId);
    } catch (error) {
      throw error;
    }
  };
};

export const removerAnexoContrato = (anexoId, contratoId) => {
  return async () => {
    try {
      return await db.doRemoverAnexoContrato(anexoId, contratoId);
    } catch (error) {
      throw error;
    }
  };
};

export const toglleSituacaoContrato = (idContrato, situacao) => {
  const toggledSituacaoContrato = isContratoEncerrado(situacao) ? 'Vigente' : 'Encerrado';
  return (dispatch) => {
    db.doToggleSituacaoContrato(idContrato, toggledSituacaoContrato);
  };
};

export const deleteContrato = (idContrato, situacao) => {
  return (dispatch) => {
    db.doToggleSituacaoContrato(idContrato, situacao);
  };
};

export const getHistorico = (id) => {
  return async (dispatch) => {
    try {
      const snapshot = await db.onceGetHitoricoContrato(id);
      let historico = snapshot.val();

      if (historico) {
        Object.keys(historico).forEach((key) => {
          historico[key].data = parseInt(key, 10);
        });
        historico = _.values(historico).reverse();
      } else {
        historico = [];
      }

      dispatch({
        type: HISTORICO_SET,
        historico,
      });

      return historico;
    } catch (error) {
      console.error('Erro ao carregar o histórico:', error);
      dispatch({
        type: 'HISTORICO_ERROR',
        error: error.message,
      });
    }
  };
};

export const setHistorico = (historico) => (dispatch) =>
  dispatch({ type: HISTORICO_SET, historico });
