import _ from 'lodash';
import {
  DOCUMENTOS_SET,
  DOCUMENTO_SET,
  DOCUMENTO_ID_SET,
  DOCUMENTOS_REQUEST,
  DOCUMENTO_UPDATED,
  DELETE_DOCUMENTO,
} from './types';
import { db } from '../firebase';

export const saveDocumento = (id, data, callback) => {
  return (dispatch) => {
    db.doSaveDocumento(id, data).then(() => {
      dispatch({ type: DOCUMENTO_UPDATED, payload: { id, data } });
      callback && callback();
    });
  };
};

export const criarDocumento = (data, callback) => {
  return (dispatch) => {
    db.doCreateDocumento(data).then((ref) => {
      callback && callback(ref.key);
    });
  };
};

export const incluirAnexoDocumento = (id, path, email) => {
  return async (dispatch) => {
    try {
      return await db.doIncluirAnexoDocumento(id, path, email);
    } catch (error) {
      throw error;
    }
  };
};

export const getDocumentos = () => {
  return (dispatch) => {
    dispatch({ type: DOCUMENTOS_REQUEST });
    db.onceGetDocumentos().then((snapshot) => {
      let documentos = snapshot.val();
      if (documentos) {
        Object.keys(documentos).map((id) => {
          documentos[id].id = id;
          documentos[id].vigente =
            documentos[id].data_fim_vigencia >= new Date().getTime() ? 'Sim' : 'Não';
          return documentos[id];
        });
        documentos = _.orderBy(_.values(documentos), [(b) => b.nome.toLowerCase()], ['asc']);
      } else {
        documentos = [];
      }

      dispatch({ type: DOCUMENTOS_SET, documentos });
    });
  };
};

export const getDocumento = (id) => {
  return (dispatch) => {
    db.onceGetDocumento(id).then((snapshot) => {
      const documento = snapshot.val();
      dispatch({ type: DOCUMENTO_SET, documento });
    });
  };
};

export const deleteDocumento = (id) => {
  return (dispatch) => {
    db.doDeleteDocumento(id).then(() => {
      dispatch({ type: DELETE_DOCUMENTO, payload: { id } });
    });
  };
};

export const setDocumento = (documento) => (dispatch) =>
  dispatch({ type: DOCUMENTO_SET, documento });

export const setDocumentoId = (documentoId) => (dispatch) =>
  dispatch({ type: DOCUMENTO_ID_SET, documentoId });
