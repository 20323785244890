import React, { useCallback, useEffect } from 'react';
import { addMonths } from 'date-fns';
import { compose } from 'recompose';
import { Field, reduxForm, formValues } from 'redux-form';
import { connect, useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import InputText from '../commons/InputText';
import InputNumber from '../commons/InputNumber';
import DatePicker from '../commons/DatePicker';
import Select from '../commons/Select';
import _ from 'lodash';
import { withSnackbar } from 'notistack';
import AutoCompleteSelect from '../commons/AutoComplete';
import { getItensReceita } from '../../actions/item_receita';
import { getEventos } from '../../actions/evento';
import styles from './styles';
import { db } from '../../firebase';
import { getReceita } from '../../actions/financial';
import * as config from './config';
import { converterParaNumero, simOuNao } from './util';
import { Radio, FormControlLabel } from '@material-ui/core';
import RadioButton from '../commons/RadioButton';
import { getContratos } from '../../actions/gestao_contratos';
import Loading from '../commons/Loading';

const ReceitaForm = ({
  handleSubmit,
  change,
  pristine,
  submitting,
  reset,
  open,
  handleClose,
  enqueueSnackbar,
}) => {
  const dispatch = useDispatch();
  const { itens_receita } = useSelector((state) => state.item_receita);
  const { eventos } = useSelector((state) => state.evento);
  const { contratos } = useSelector((state) => state.contratos);
  const { receitaId, loadingReceita } = useSelector((state) => state.financial);

  useEffect(() => {
    const fetchData = async () => {
      await Promise.all([
        dispatch(getItensReceita()),
        dispatch(getEventos()),
        dispatch(getContratos()),
      ]);
    };
    fetchData();
  }, [dispatch]);

  useEffect(() => {
    if (receitaId) {
      dispatch(getReceita(receitaId));
    }
  }, [receitaId, dispatch]);

  const save = (receita) => {
    let promise;

    receita.valor = converterParaNumero(receita.valor);

    if (receitaId) {
      promise = db.doUpdateReceita(receitaId, receita);
    } else {
      receita.parcela = `1/${receita.numero_parcelas}`;
      promise = db.doSaveReceita(receita);
      const { numero_parcelas, data } = receita;

      if (numero_parcelas > 1) {
        for (let i = 2; i <= numero_parcelas; i++) {
          const clone = _.cloneDeep(receita);
          clone.parcela = `${i}/${numero_parcelas}`;
          clone.data = addMonths(new Date(data), i - 1).getTime();
          db.doSaveReceita(clone);
        }
      }
    }

    promise
      .then(() => {
        enqueueSnackbar('Receita salva com sucesso!', {
          variant: 'success',
        });
        handleClose(true);
        reset();
      })
      .catch(() =>
        enqueueSnackbar('Erro ao salvar receita.', {
          variant: 'error',
        })
      );
  };

  const handleChangeItemReceita = (item) => {
    const item_receita = itens_receita.find((i) => i.nome === item);
    if (item_receita) {
      const { tipo, descricao } = item_receita;
      tipo && change('tipo', tipo);
      descricao && change('descricao', descricao);
    }
  };

  const handleCancel = useCallback(() => {
    handleClose();
    reset();
  }, [handleClose, reset]);

  const { tipos_receita } = config;
  const contratosValidos = contratos.filter((contrato) => contrato.situacao !== 'Excluído');

  return (
    <Dialog
      open={open}
      onClose={handleClose.bind(this, null)}
      fullWidth
      maxWidth="lg"
      aria-labelledby="form-dialog-title">
      <Loading Loading={loadingReceita} />
      <DialogTitle id="form-dialog-title">Receita</DialogTitle>
      <form onSubmit={handleSubmit(save)}>
        {!loadingReceita && (
          <DialogContent>
            <Grid container spacing={4} hidden={false} justifyContent="center">
              <Grid item sm={6}>
                <Field
                  name="item_receita"
                  options={itens_receita
                    .filter((item) => !item.desabilitado)
                    .map((d) => ({ label: d.nome, value: d.nome }))}
                  component={AutoCompleteSelect}
                  label="Item de receita"
                  onChange={(item) => handleChangeItemReceita(item)}
                />
              </Grid>
              <Grid item sm={3}>
                <Field name="tipo" component={Select} label="Tipo">
                  <MenuItem value="">
                    <em>Selecione</em>
                  </MenuItem>
                  {tipos_receita.map((t) => (
                    <MenuItem key={t} value={t}>
                      {t}
                    </MenuItem>
                  ))}
                </Field>
              </Grid>
              <Grid item sm={3}>
                <Field name="numero_parcelas" component={InputText} label="Número de parcelas" />
              </Grid>
              <Grid item sm={6}>
                <Field name="descricao" component={InputText} label="Descrição" />
              </Grid>
              <Grid item sm={3}>
                <Field name="data" component={DatePicker} label="Data" />
              </Grid>
              <Grid item sm={3}>
                <Field name="valor" component={InputNumber} label="Valor" />
              </Grid>
              <Grid item container sm={12} spacing={1}>
                <Grid item sm={2}>
                  <Field
                    name="relacao_evento"
                    component={RadioButton}
                    label="Receita relacionada a evento?">
                    {_.map(simOuNao, (relacaoEvento, key) => (
                      <FormControlLabel
                        key={key}
                        value={relacaoEvento.value}
                        control={<Radio />}
                        label={relacaoEvento.label}
                      />
                    ))}
                  </Field>
                </Grid>
                <DadosEvento eventos={eventos} />
                <Grid item sm={2}>
                  <Field
                    name="relacao_contrato"
                    component={RadioButton}
                    label="Receita relacionada a contrato?">
                    {_.map(simOuNao, (relacaoContrato, key) => (
                      <FormControlLabel
                        key={key}
                        value={relacaoContrato.value}
                        control={<Radio />}
                        label={relacaoContrato.label}
                      />
                    ))}
                  </Field>
                </Grid>
                <DadosContrato contratos={contratosValidos} />
              </Grid>
            </Grid>
          </DialogContent>
        )}
        <DialogActions>
          <Button onClick={handleCancel} color="default">
            Cancelar
          </Button>
          <Button
            variant="contained"
            type="submit"
            disabled={pristine || submitting}
            color="primary"
            onClick={() => window.location.reload()}>
            Salvar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

const DadosEvento = formValues('relacao_evento')(({ relacao_evento, eventos }) => {
  if (relacao_evento === 'Sim') {
    return (
      <React.Fragment>
        <Grid item sm={4}>
          <Field name="id_evento" component={Select} label="Nome do evento">
            <MenuItem value="">
              <em>Selecione</em>
            </MenuItem>
            {eventos
              .filter((b) => !b.desabilitado)
              .map((b) => (
                <MenuItem key={b.id} value={b.id}>
                  {b.nome}
                </MenuItem>
              ))}
          </Field>
        </Grid>
      </React.Fragment>
    );
  }
  return null;
});

const DadosContrato = formValues('relacao_contrato')(({ relacao_contrato, contratos }) => {
  if (relacao_contrato === 'Sim') {
    return (
      <React.Fragment>
        <Grid item sm={3}>
          <Field name="id_contrato" component={Select} label="Contrato">
            <MenuItem value="">
              <em>Selecione</em>
            </MenuItem>
            {contratos
              .filter((b) => !b.desabilitado)
              .map((b) => (
                <MenuItem key={b.id} value={b.id}>
                  {b.nome}
                </MenuItem>
              ))}
          </Field>
        </Grid>
      </React.Fragment>
    );
  }
  return null;
});

ReceitaForm.propTypes = {
  classes: PropTypes.object.isRequired,
};

const validate = (values) => {
  const errors = {};
  const requiredFields = ['tipo', 'item_receita', 'data', 'descricao', 'valor', 'numero_parcelas'];
  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = ' Obrigatório';
    }
  });
  return errors;
};

const mapStateToProps = (state) => ({
  initialValues: state.financial.receita,
});

export default compose(
  connect(mapStateToProps, { getReceita, getItensReceita, getEventos, getContratos }),
  withStyles(styles)
)(
  reduxForm({
    form: 'receita',
    validate,
    enableReinitialize: true,
  })(withSnackbar(ReceitaForm))
);
