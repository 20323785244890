import React, { useState, useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import _ from 'lodash';
import PropTypes from 'prop-types';
import ReplayIcon from '@material-ui/icons/Replay';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import * as roles from '../../constants/roles';
import tableI18n from '../TableLocalization';
import SindilegisBar from '../commons/SindilegisBar';
import styles from './styles';
import Loading from '../commons/Loading';
import * as routes from '../../constants/routes';
import MenuButton from '../commons/MenuButton';
import { formataData, getRoutes } from '../commons/util';
import {
  getContratos,
  setContrato,
  setContratoId,
  toglleSituacaoContrato,
  getHistorico,
  deleteContrato,
  removerAnexoContrato,
} from '../../actions/gestao_contratos';
import { withSnackbar } from 'notistack';
import { isContratoEncerrado } from './components/common/utils';
import { getReceitasEDespesas } from '../../actions/financial';
import ButtonComponent from '../commons/ButtonComponent';
import { Grid, Link, Container, withStyles } from '@material-ui/core';
import MaterialTable from 'material-table';
import ConfirmDialog from '../commons/ConfirmDialog';
import DocumentoContratoForm from './documentoContrato';
import ArquivosDocumentoContrato from './anexos/anexos';
import Detalhamento from './components/detalhamento';
import { getEventos } from '../../actions/evento';

const ContratosTable = ({ authUser, classes, enqueueSnackbar, removerAnexoContrato }) => {
  const [openDocumento, setDocumentoOpen] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [confirmAction, setConfirmAction] = useState(() => () => {});
  const [confirmMessage, setConfirmMessage] = useState('');
  const [openArquivos, setOpenArquivos] = useState(false);
  const [openObsEHist, setOpenObsEHist] = useState(false);
  const [observacoes, setObservacoes] = useState([]);
  const [historicoContrato, setHistoricoContrato] = useState([]);
  const [title, setTitle] = useState('');
  const [isOnlyView, setIsOnlyView] = useState(false);
  const [revenuesContrato, setRevenuesContrato] = useState([]);
  const [expensesContrato, setExpensesContrato] = useState([]);
  const [isDetalhamento, setIsDetalhamento] = useState(false);
  const [nomeDocumento, setNomeDocumento] = useState('');
  const [loadingHistorico, setLoadingHistorico] = useState(false);
  const [situacaoDocumento, setSituacaoDocumento] = useState();
  const [isEditing, setIsEditing] = useState(false);
  const [anexosContrato, setAnexosContrato] = useState([]);

  const dispatch = useDispatch();
  const location = useLocation();

  const { contratos, loading } = useSelector((state) => state.contratos);
  const { expenses, receitas } = useSelector((state) => state.financial);
  const { eventos } = useSelector((state) => state.evento);
  const contratosAtivos = contratos?.filter((contrato) => contrato.situacao !== 'Excluído');
  const loadingFinancial = useSelector((state) => state.financial.loading);
  const loadingPage = loading || loadingFinancial;
  const canEdit = _.includes(authUser.roles, roles.GESTOR_CONTRATOS);
  const currentPath = location.pathname;
  const buttonRoutes = getRoutes(routes, currentPath);

  const query = new URLSearchParams(location.search);
  const situacao = query.get('situacao');

  useEffect(() => {
    dispatch(getContratos());
    dispatch(getReceitasEDespesas());
    dispatch(getEventos());
  }, [dispatch]);

  const handleCreateTema = () => {
    setDocumentoOpen(true);
  };
  const handleTemaClickOpen = ({ nome, situacao }) => {
    setDocumentoOpen(true);
    setNomeDocumento(nome);
    setSituacaoDocumento(situacao);
    setIsEditing(true);
  };

  const handleDocumentoClose = (reload) => {
    setDocumentoOpen(false);
    setIsOnlyView(false);
    dispatch(setContrato(null));
    dispatch(setContratoId(null));
    if (reload) {
      dispatch(getContratos());
    }
    setIsEditing(false);
    setNomeDocumento('');
    setSituacaoDocumento(null);
    setContrato(null);
    setContratoId(null);
  };

  const handleConfirmOpen = (action, message) => {
    setConfirmAction(() => action);
    setConfirmMessage(message);
    setConfirmOpen(true);
  };

  const handleConfirmClose = (confirm) => {
    if (confirm) {
      confirmAction();
    }
    setConfirmOpen(false);
  };

  const handleToggleSituacaoTema = (idContrato, situacao) => {
    dispatch(toglleSituacaoContrato(idContrato, situacao));
    dispatch(getContratos());
  };

  const filterByQueryParam = (contrato, situacao) => {
    const filterApplied = situacao;
    if (!filterApplied) {
      return true;
    }
    const situacaoMatch = situacao ? contrato?.situacao === situacao : true;
    return situacaoMatch;
  };

  const contratosFiltrados = contratosAtivos.filter(
    (contrato) => situacao == null || filterByQueryParam(contrato, situacao)
  );

  const contratosOrdenados = _.orderBy(
    _.values(contratosFiltrados),
    [(c) => c.numero_contrato],
    ['asc']
  );

  const titleDefault = 'Gerenciar contratos e documentos';
  const titulo =
    situacao === null
      ? titleDefault
      : situacao === 'Vigente'
        ? titleDefault + ' - VIGENTES'
        : titleDefault + '- ENCERRADOS/ARQUIVADOS';

  const handlecloseArquivos = () => {
    setOpenArquivos(false);
  };

  const handleDeleteContrato = (contratoId) => {
    if (expenses.some((e) => e.id_contrato === contratoId)) {
      enqueueSnackbar('O Contrato está associado a uma ou mais despesas e não pode ser excluído!', {
        variant: 'error',
      });
      return;
    } else if (receitas.some((r) => r.id_contrato === contratoId)) {
      enqueueSnackbar('O Contrato está associado a uma ou mais receitas e não pode ser excluído!', {
        variant: 'error',
      });
      return;
    } else if (eventos.some((e) => e.id_documento.some((doc) => doc.value === contratoId))) {
      enqueueSnackbar('O Contrato está associado a um ou mais eventos e não pode ser excluído!', {
        variant: 'error',
      });
      return;
    }

    dispatch(deleteContrato(contratoId, 'Excluído'));
    enqueueSnackbar('Contrato excluído com sucesso!', { variant: 'success' });
    dispatch(getContratos());
  };

  const handleOpenObsEHist = async (row) => {
    setLoadingHistorico(true);
    const historicos = await dispatch(getHistorico(row.id));
    setLoadingHistorico(false);
    setHistoricoContrato(historicos);
    setOpenObsEHist(true);
    setObservacoes(row?.observacoes);
    setTitle(row?.nome);
    setAnexosContrato(row?.anexos);
  };

  const handleOpenDetalhamento = (row) => {
    const despesasContrato = expenses.filter((e) => e.id_contrato === row.id);
    const receitasContrato = receitas.filter((r) => r.id_contrato === row.id);
    setOpenObsEHist(true);
    setRevenuesContrato(receitasContrato);
    setExpensesContrato(despesasContrato);
    setIsDetalhamento(true);
  };

  const handleCloseDetalhamento = () => {
    setOpenObsEHist(false);
    setIsDetalhamento(false);
    setRevenuesContrato([]);
    setExpensesContrato([]);
    setObservacoes([]);
    setTitle('');
    setHistoricoContrato([]);
  };

  const handleOpenDetail = (row) => {
    setIsOnlyView(true);
    setDocumentoOpen(true);
    dispatch(setContratoId(row.id));
  };

  const hasReceitaOuDespesa = (row) => {
    const hasDepesas = expenses.filter((e) => e.id_contrato === row.id);
    const hasReceitas = receitas.filter((r) => r.id_contrato === row.id);

    return hasDepesas.length > 0 || hasReceitas.length > 0;
  };

  const handleClick = (row) => {
    if (hasReceitaOuDespesa(row)) {
      handleOpenDetalhamento(row);
    } else {
      enqueueSnackbar('Não existem receitas ou despesas relacionadas para esse contrato', {
        variant: 'warning',
      });
    }
  };

  const handleDeleteArquivoContrato = (contratoId, anexoId) => {
    return new Promise((resolve, reject) => {
      handleConfirmOpen(() => {
        removerAnexoContrato(contratoId, anexoId)
          .then(() => {
            enqueueSnackbar('Arquivo excluído com sucesso', { variant: 'success' });
            resolve();
          })
          .catch((error) => {
            enqueueSnackbar('Erro ao excluir arquivo', { variant: 'error' });
            reject(error);
          });
      }, 'Confirma a exclusão do arquivo?');
    });
  };

  return (
    <React.Fragment>
      <SindilegisBar />
      <Loading loading={loadingPage} />
      {!loading && !loadingFinancial && (
        <Container maxWidth="xl">
          <Grid item xs={12} md={12}>
            <Grid item style={{ paddingBottom: 8 }}>
              <MenuButton buttonsRoutes={buttonRoutes} location={location} />
            </Grid>
            <Grid item style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <ButtonComponent variant="insert" onClick={handleCreateTema}>
                Cadastrar contrato/documento
              </ButtonComponent>
            </Grid>
            <div className={classes.demo}>
              <MaterialTable
                columns={[
                  {
                    title: 'Tipo de Objeto',
                    field: 'tipo_objeto',
                    cellStyle: {
                      whiteSpace: 'nowrap',
                    },
                  },
                  {
                    title: 'Nome do arquivo',
                    field: 'nome',
                    render: (row) => (
                      <Link
                        component="button"
                        variant="body2"
                        onClick={() => handleOpenDetail(row)}>
                        {row.nome}
                      </Link>
                    ),
                    cellStyle: {
                      whiteSpace: 'nowrap',
                    },
                  },
                  {
                    title: 'Número',
                    field: 'numero_contrato',
                    cellStyle: {
                      textAlign: 'center',
                      whiteSpace: 'nowrap',
                    },
                  },
                  {
                    title: 'Data de carregamento',
                    field: 'data_carregamento',
                    render: (row) => formataData(row.data_carregamento),
                    cellStyle: {
                      textAlign: 'center',
                      whiteSpace: 'nowrap',
                    },
                  },
                  {
                    title: 'Vigente',
                    field: 'vigente',
                    render: (row) => (row.tipo_objeto === 'Documento' ? '-' : row.vigente),
                    cellStyle: {
                      textAlign: 'center',
                      whiteSpace: 'nowrap',
                    },
                  },
                  {
                    title: 'Início da vigência',
                    field: 'data_inicio',
                    render: (row) =>
                      row.tipo_objeto === 'Documento' ? '-' : formataData(row.data_inicio),
                    cellStyle: {
                      textAlign: 'center',
                      whiteSpace: 'nowrap',
                    },
                  },
                  {
                    title: 'Fim da vigência',
                    field: 'data_fim',
                    render: (row) =>
                      row.tipo_objeto === 'Documento' ? '-' : formataData(row.data_fim),
                    cellStyle: {
                      textAlign: 'center',
                      whiteSpace: 'nowrap',
                    },
                  },
                  {
                    title: 'Renovação/Rescisão (alerta)',
                    field: 'data_alerta',
                    render: (row) =>
                      row.tipo_objeto === 'Documento' ? '-' : formataData(row.data_alerta),
                    cellStyle: {
                      textAlign: 'center',
                      whiteSpace: 'nowrap',
                    },
                  },
                  {
                    title: 'Pagamentos/Recebimentos',
                    field: 'pagamentos',
                    render: (row) => (
                      <Link component="button" variant="body2" onClick={() => handleClick(row)}>
                        Detalhamento
                      </Link>
                    ),
                    cellStyle: {
                      textAlign: 'center',
                      whiteSpace: 'nowrap',
                    },
                  },
                ]}
                data={contratosOrdenados}
                title={titulo}
                actions={[
                  (rowData) => {
                    const isEncerrado = isContratoEncerrado(rowData.situacao);
                    const tipo = rowData.tipo_objeto;
                    const nome = rowData.nome;
                    const situacao = rowData.situacao;
                    const id = rowData.id;
                    const numero = rowData.numero_contrato;

                    return {
                      icon: () => (isEncerrado ? <ReplayIcon /> : <HighlightOffIcon />),
                      tooltip: isEncerrado ? 'Cancelar encerramento' : `Encerrar/Arquivar ${tipo}`,
                      onClick: (event) => {
                        handleConfirmOpen(
                          () => handleToggleSituacaoTema(id, situacao),
                          isEncerrado
                            ? `Deseja revalidar do ${nome}`
                            : `Deseja encerrar o ${tipo.toLowerCase()} de número ${numero}`
                        );
                      },
                    };
                  },
                  (rowData) => {
                    const tipo = rowData.tipo_objeto;
                    const id = rowData.id;

                    return {
                      icon: 'edit',
                      tooltip: canEdit ? `Editar ${tipo}` : 'Detalhe',
                      onClick: () => {
                        dispatch(setContratoId(id));
                        handleTemaClickOpen(rowData);
                      },
                    };
                  },
                  (rowData) => {
                    const id = rowData.id;
                    const tipo = rowData.tipo_objeto;
                    const numero = rowData.numero_contrato;

                    return {
                      icon: 'delete',
                      tooltip: `Excluir ${tipo}`,
                      onClick: () =>
                        handleConfirmOpen(
                          () => handleDeleteContrato(id),
                          `Confirma a exclusão permanente do ${tipo.toLowerCase()} de número ${numero}?`
                        ),
                    };
                  },
                  (rowData) =>
                    rowData.anexos &&
                    rowData.anexos !== null &&
                    Object.values(rowData.anexos).some((anexo) => anexo.situacao !== 'Excluído')
                      ? {
                          icon: 'folder',
                          tooltip: 'Ver arquivos',
                          onClick: () => {
                            dispatch(setContratoId(rowData.id));
                            setOpenArquivos(true);
                          },
                        }
                      : null,
                  {
                    icon: 'notes',
                    tooltip: 'Ver observações e histórico',
                    onClick: (event, rowData) => {
                      handleOpenObsEHist(rowData);
                    },
                  },
                  (rowData) => {
                    const createBy = rowData.by;
                    const tipo = rowData.tipo_objeto;
                    const create = rowData.data_carregamento;

                    return {
                      icon: 'info',
                      tooltip: `${tipo} criado por ${createBy} em ${formataData(create)}`,
                    };
                  },
                ].filter(Boolean)}
                options={{
                  actionsColumnIndex: -1,
                  pageSize: 10,
                  pageSizeOptions: [10, 20, 50, 100],
                }}
                localization={tableI18n}
              />
            </div>
          </Grid>
          <ConfirmDialog open={confirmOpen} message={confirmMessage} onClose={handleConfirmClose} />
          <DocumentoContratoForm
            open={openDocumento}
            handleClose={handleDocumentoClose}
            by={authUser?.email}
            isOnlyView={isOnlyView}
            nomeDocumento={nomeDocumento}
            situacaoDocumento={situacaoDocumento}
            isEditing={isEditing}
          />
          <ArquivosDocumentoContrato
            open={openArquivos}
            handleClose={handlecloseArquivos}
            handleDeleteArquivoContrato={handleDeleteArquivoContrato}
          />
          <Detalhamento
            open={openObsEHist}
            onClose={handleCloseDetalhamento}
            observacoes={observacoes}
            historico={historicoContrato}
            title={title}
            receitas={revenuesContrato}
            despesas={expensesContrato}
            authUser={authUser}
            isDetalhamento={isDetalhamento}
            loadingFinancial={loadingFinancial}
            loadingHistorico={loadingHistorico}
            anexos={anexosContrato}
          />
        </Container>
      )}
    </React.Fragment>
  );
};

ContratosTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = () => ({});

export default connect(mapStateToProps, { removerAnexoContrato })(
  withStyles(styles)(withSnackbar(ContratosTable))
);
