import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Fab from '@material-ui/core/Fab';
import tableI18n from '../TableLocalization';
import MaterialTable, { MTableToolbar } from 'material-table';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import {
  getReceitas,
  setReceitaId,
  setReceita,
  deleteReceita,
  atualizarReceita,
} from '../../actions/financial';
import RangeDatePicker from '../commons/RangeDatePicker';
import ConfirmDialog from '../commons/ConfirmDialog';
import SindilegisBar from '../commons/SindilegisBar';
import { downloadXlsx } from '../commons/util';
import ReceitaForm from './receita';
import EstornoReceita from './estorno_receita';
import styles from './styles';
import * as routes from '../../constants/routes';
import * as roles from '../../constants/roles';
import {
  getDataInicial,
  getDataFinal,
  converterParaNumero,
  formatarValor,
  formataData,
  calcularEstorno,
  somadorDouble,
} from './util';
import CreditoReceita from './credito_receita';
import FileUploadReceita from '../commons/FileUploadReceita';
import { storage } from '../../firebase';
import { useSnackbar } from 'notistack';
import ButtonComponent from '../commons/ButtonComponent';
import MenuButton from '../commons/MenuButton';
import { getRoutes } from '../commons/util';

const useStyles = makeStyles(styles);

const ListaReceitas = ({ authUser }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { receitas } = useSelector((state) => state.financial);

  const [state, setState] = useState({
    loading: true,
    open: false,
    fromDate: getDataInicial(),
    toDate: getDataFinal(),
    filteredReceitas: [],
    confirmOpen: false,
    confirmMessage: '',
    confirmAction: Function,
    valoresSelecionados: 0,
  });

  useEffect(() => {
    dispatch(getReceitas());
  }, [dispatch]);

  useEffect(() => {
    if (receitas) {
      filterReceitas(state.fromDate, state.toDate);
    }
  }, [receitas, state.fromDate, state.toDate]);

  const handleConfirmOpen = (confirmAction, confirmMessage) => {
    setState((prevState) => ({
      ...prevState,
      confirmOpen: true,
      confirmAction,
      confirmMessage,
    }));
  };

  const handleConfirmClose = (confirm) => {
    if (confirm) {
      state.confirmAction();
      setState((prevState) => ({ ...prevState, confirmOpen: false }));
    } else {
      setState((prevState) => ({ ...prevState, confirmOpen: false }));
    }
  };

  const editReceita = (event, id) => {
    dispatch(setReceitaId(id));
    setState((prevState) => ({ ...prevState, open: true }));
    event.stopPropagation();
  };

  const handleOpen = () => {
    dispatch(setReceitaId(null));
    dispatch(setReceita(null));
    setState((prevState) => ({ ...prevState, open: true }));
    dispatch(getReceitas());
  };

  const handleClose = () => {
    setState((prevState) => ({ ...prevState, open: false, start: null, end: null }));
    dispatch(setReceitaId(null));
    dispatch(setReceita(null));
    dispatch(getReceitas());
  };

  const handleDateChange = (date) => {
    setState((prevState) => ({ ...prevState, ...date }));
    filterReceitas(date.fromDate.getTime(), date.toDate.getTime());
  };

  const filterReceitas = (fromDate, toDate) => {
    if (fromDate && toDate) {
      const filteredReceitas = receitas.filter((e) => fromDate <= e.data && toDate >= e.data);
      setState((prevState) => ({ ...prevState, filteredReceitas }));
    }
  };

  const selecionarReceita = (event, id) => {
    const { filteredReceitas } = state;
    const index = filteredReceitas.findIndex((d) => d.id === id);
    const despesa = {
      ...filteredReceitas[index],
      selecionado: !filteredReceitas[index].selecionado,
    };
    filteredReceitas.splice(index, 1, despesa);
    setState((prevState) => ({ ...prevState, filteredReceitas: [...filteredReceitas] }));
    event.stopPropagation();
  };

  const deleteAnexo = (receita, anexo) => {
    handleConfirmOpen(() => {
      if (receita.anexos && receita.anexos[anexo.id]) {
        delete receita.anexos[anexo.id];
      }

      const anexos = receita.anexos;
      dispatch(atualizarReceita(receita.id, { anexos }));
      storage.removerAnexo(anexo.url);
    }, `Confirma exclusão do documento ${anexo.nome}?`);
  };

  const renderDetail = (receita, canEdit) => {
    const { id, descricao, data, parcela, data_credito, anexos } = receita;

    return (
      <Grid container spacing={4} className={classes.despesa}>
        <Grid item sm={8}>
          <Typography variant="body2" color="textSecondary">
            Descrição
          </Typography>
          <Typography variant="body1">{descricao}</Typography>
        </Grid>
        <Grid item sm={2}>
          <Typography variant="body2" color="textSecondary">
            Parcela
          </Typography>
          <Typography variant="body1">{parcela}</Typography>
        </Grid>
        <Grid item sm={2}>
          <Typography variant="body2" color="textSecondary">
            Data
          </Typography>
          <Typography variant="body1">{formataData(data)}</Typography>
        </Grid>
        {canEdit && (
          <Grid
            item
            container
            sm={12}
            spacing={2}
            justifyContent="space-between"
            alignItems="center">
            <Grid item container sm={6} spacing={1}>
              <CreditoReceita receita={receita} />
            </Grid>
            <Grid item container sm={4} spacing={1}>
              <EstornoReceita receita={receita} />
            </Grid>
            <FileUploadReceita
              id={id}
              anexos={anexos}
              receitaCreditada={!!data_credito}
              handleDelete={(anexo) => deleteAnexo(receita, anexo)}
              canEdit={canEdit}
              canDelete={authUser.email === 'amanda@sindilegis.org.br'}
              enqueueSnackbar={enqueueSnackbar}
            />
          </Grid>
        )}
      </Grid>
    );
  };

  const canEdit = _.includes(authUser.roles, roles.ADMIN_FINANCEIRO);
  const valoresSelecionados = state.filteredReceitas
    .filter((d) => d.selecionado)
    .map((e) => converterParaNumero(e.valor))
    .reduce((a, b) => a + b, 0);
  const currentPath = history.location.pathname;
  const buttonsRoutes = getRoutes(routes, currentPath);

  return (
    <div>
      <Fab variant="extended" aria-label="delete" className={classes.soma}>
        {formatarValor(valoresSelecionados)}
      </Fab>
      <SindilegisBar />
      <Container maxWidth="xl" className={classes.containerBottomPadding}>
        <MenuButton buttonsRoutes={buttonsRoutes} location={history.location} />
        <Grid
          container
          item
          md={'auto'}
          spacing={1}
          justifyContent="flex-end"
          alignItems="flex-end"
          className={classes.areaOperacoes}>
          {canEdit && (
            <>
              <Grid item>
                <ButtonComponent variant="insert" onClick={handleOpen}>
                  Cadastrar Receita
                </ButtonComponent>
              </Grid>
            </>
          )}
        </Grid>
        <Grid
          item
          container
          spacing={1}
          md={5}
          justifyContent="flex-start"
          style={{ padding: '10px' }}>
          <RangeDatePicker
            fromDate={state.fromDate}
            toDate={state.toDate}
            onChange={(data) => handleDateChange(data)}
          />
        </Grid>
        <Grid container direction="column" alignItems="center">
          <Grid container>
            <ReceitaForm open={state.open} handleClose={handleClose} />
          </Grid>
        </Grid>
        <MaterialTable
          columns={[
            {
              title: '',
              field: 'selecionado',
              render: (row) => {
                return (
                  <Checkbox
                    checked={!!row.selecionado}
                    onChange={(event) => selecionarReceita(event, row.id)}
                    onClick={(e) => e.stopPropagation()}
                    inputProps={{
                      'aria-label': 'Selecionar receita',
                    }}
                  />
                );
              },
            },
            { title: 'Item', field: 'item_receita' },
            { title: 'Tipo', field: 'tipo' },
            { title: 'Descrição', field: 'descricao' },
            {
              title: 'Valor',
              field: 'valor',
              render: (row) => <span>{formatarValor(row.valor)}</span>,
            },
          ]}
          data={state.filteredReceitas}
          title="Receitas"
          detailPanel={(row) => renderDetail(row, canEdit)}
          onRowClick={(event, rowData, togglePanel) => togglePanel()}
          options={{
            actionsColumnIndex: -1,
            pageSize: 10,
            pageSizeOptions: [10, 20, 50, 100],
            exportButton: true,
            exportAllData: true,
            exportCsv: (columns, data) => {
              columns = columns
                .concat([
                  { title: 'Data', field: 'data' },
                  { title: 'Parcela', field: 'parcela' },
                ])
                .filter((c) => !_.isEmpty(c.title));

              data.forEach((d) => {
                d.data = formataData(d.data);
              });

              downloadXlsx(columns, data, 'receitas.xlsx');
            },
          }}
          actions={[
            {
              icon: 'edit',
              tooltip: 'Editar',
              onClick: (event, row) => editReceita(event, row.id),
              disabled: !canEdit,
            },
            (rowData) => {
              if (
                !canEdit ||
                (authUser.email !== 'amanda@sindilegis.org.br' && rowData.data_credito)
              ) {
                return;
              }
              return {
                icon: 'delete',
                tooltip: 'Excluir',
                onClick: (event, row) =>
                  handleConfirmOpen(
                    () => dispatch(deleteReceita(row.id)),
                    'Confirma exclusão da receita?'
                  ),
              };
            },
          ]}
          localization={tableI18n}
          components={{
            Toolbar: (props) => (
              <div>
                <MTableToolbar {...props} />
                <div style={{ padding: '10px' }}>
                  Total:{' '}
                  {formatarValor(
                    props.renderData.map((e) => calcularEstorno(e)).reduce(somadorDouble, 0)
                  )}
                </div>
              </div>
            ),
          }}
        />
        <ConfirmDialog
          open={state.confirmOpen}
          message={state.confirmMessage}
          onClose={handleConfirmClose}
        />
      </Container>
    </div>
  );
};

ListaReceitas.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default ListaReceitas;
