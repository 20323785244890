import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { withStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import AttachFile from '@material-ui/icons/AttachFile';
import { storage } from '../../firebase';
import { incluirAnexoReceita } from '../../actions/financial';

const styles = (theme) => ({
  input: {
    display: 'none',
  },
});

class FileUploadReceita extends Component {
  state = { anexos: {} };

  componentDidMount() {
    if (this.props.anexos) {
      this.setState({ anexos: this.props.anexos });
    }
  }

  handleCapture = (event) => {
    const file = event.target.files[0];
    const { id } = this.props;
    storage
      .uploadAnexoReceita(`${id}/${file.name}`, file)
      .then(({ metadata }) => {
        const anexo = { nome: file.name, url: metadata.fullPath };
        this.props.incluirAnexoReceita(id, anexo);
        const { anexos } = this.state;
        anexos[anexo.nome] = anexo;
        this.setState({ anexos });
        this.props.enqueueSnackbar('Comprovante incluído com sucesso', { variant: 'success' });
      })
      .catch((error) => {
        this.props.enqueueSnackbar('Erro ao incluir comprovante', { variant: 'error' });
        console.error('Erro ao incluir comprovante:', error);
      });
  };

  render() {
    const { classes, id, canEdit, disabled } = this.props;
    const { anexos } = this.state;

    return (
      <Fragment>
        <Grid item container sm={12} spacing={2}>
          {_.map(anexos, (item, index) => this.renderAnexo(item, index))}
        </Grid>
        {canEdit && (
          <Grid item container sm={12} spacing={2}>
            <input
              accept="application/pdf"
              className={classes.input}
              id={id}
              onChange={(event) => this.handleCapture(event)}
              type="file"
            />
            <label htmlFor={id}>
              <Button
                color="primary"
                component="span"
                size="medium"
                style={{ marginRight: 8 }}
                disabled={disabled}>
                <AttachFile /> Anexar Comprovante
              </Button>
            </label>
          </Grid>
        )}
      </Fragment>
    );
  }

  renderAnexo(item, id) {
    const { data_credito, canDelete, canEdit, disabled } = this.props;

    if (!canEdit || (!canDelete && data_credito) || disabled) {
      return (
        <Chip
          key={id}
          onClick={() => storage.downloadAnexo(item.url)}
          label={item.nome}
          clickable
          variant="outlined"
        />
      );
    } else {
      return (
        <Chip
          key={id}
          onDelete={() => this.handleDelete({ ...item, id })}
          onClick={() => storage.downloadAnexo(item.url)}
          label={item.nome}
          clickable
          variant="outlined"
        />
      );
    }
  }

  handleDelete(item) {
    this.props.handleDelete(item);
  }
}

export default connect(null, { incluirAnexoReceita })(
  withStyles(styles, { withTheme: true })(FileUploadReceita)
);
