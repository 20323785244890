import React, { useEffect } from 'react';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';
import ptLocale from 'date-fns/locale/pt-BR';
import PropTypes from 'prop-types';
import {
  Accordion, AccordionSummary, AccordionDetails, Grid, Table, TableBody,
  TableCell, TableHead, TableRow, Toolbar, Typography, makeStyles,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { getHistorico, setHistorico } from '../../actions/filiado';
import { formataData } from '../Financial/util';
import { showHistorico } from './config';

const useStyles = makeStyles(() => ({
  heading: {
    fontWeight: 'bold',
  },
  accordion: {
    width: '100%',
    marginTop: 30,
  },
}));

const HistoricoFiliado = ({ id }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const historico = useSelector((state) => state.filiado.historico || []);

  useEffect(() => {
    if (id) {
      dispatch(getHistorico(id));
    }
    return () => {
      dispatch(setHistorico([]));
    };
  }, [id, dispatch]);

  if (_.isEmpty(historico)) return null;

  return (
    <Accordion className={classes.accordion}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography className={classes.heading}>Histórico</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <HistoricoTable historico={historico} />
      </AccordionDetails>
    </Accordion>
  );
};

const HistoricoTable = ({ historico }) => (
  <Grid style={{ width: '100%' }}>
    <Toolbar>
      <Typography variant="h6">Histórico de alterações</Typography>
    </Toolbar>
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell>Data</TableCell>
          <TableCell>Alteração</TableCell>
          <TableCell>Responsável</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {historico.map((row) => (
          <TableRow key={`${row.data}-${row.nome_responsavel}`}>
            <TableCell>{format(new Date(row.data), 'dd/MM/yyyy', { locale: ptLocale })}</TableCell>
            <TableCell>{renderAlteracoes(row.diff)}</TableCell>
            <TableCell>{row.nome_responsavel}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </Grid>
);

const renderAlteracoes = (diff) => {
  if (!diff || Object.keys(diff).length === 0) {
    return <Typography variant="body2">Nenhuma alteração registrada.</Typography>;
  }

  return Object.entries(diff)
    .filter(([key, value]) => {
      if (key === 'tableData' || key === 'id') return false;
      return true;
    })
    .map(([key, value]) => (
      <Typography variant="body2" key={key}>
        {`${getLabel(key)}: ${formatDiffValue(key, value)}`}
      </Typography>
    ));

};

const getLabel = (key) => {
  const field = showHistorico.find((item) => item.value === key);
  return field ? field.label : key;
};

const formatDiffValue = (key, value) => {
  if (key === 'logRegister') return value.info;
  if (key === 'anexosFlag' || key === 'data_desfiliacao' || key === 'ultima_filiacao') return formataData(value);
  if (key === 'anexos') return Object.values(value).map((anexo) => anexo.nome).join(', ');
  return value;
};

HistoricoFiliado.propTypes = {
  id: PropTypes.string,
};

export default HistoricoFiliado;
