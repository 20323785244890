import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import MaterialTable from 'material-table';
import { getUsersAndRoles, setUserId } from '../../actions/user';
import { functions } from '../../firebase';
import tableI18n from '../TableLocalization';
import SindilegisBar from '../commons/SindilegisBar';
import styles from './styles';
import Loading from '../commons/Loading';
import EditarPerfis from './perfis';
import CreateUser from './createUser';
import Usuario from './usuario';
import { formataData } from '../Financial/util';
import { rolesDescription } from '../../constants/roles';
import ButtomComponent from '../commons/ButtonComponent';
import ConfirmDialog from '../commons/ConfirmDialog';
import { reduxForm } from 'redux-form';
import { withSnackbar } from 'notistack';
import { CircularProgress } from '@material-ui/core';
import CustomCard from '../commons/CustomCard';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import ButtonComponent from '../commons/ButtonComponent';

class Roles extends React.Component {
  state = {
    deleteLoading: false,
    open: false,
    createUserOpen: false,
    usuarioEscritorioOpen: false,
    confirmMessage: '',
    confirmOpen: false,
    confirmAcion: Function,
    openDialog: false,
  };

  componentDidMount() {
    this.props.getUsersAndRoles();
  }

  handleRolesOpen = () => {
    this.setState({ open: true });
  };
  handleCreateUserOpen = () => {
    this.setState({ createUserOpen: true });
  };

  handleUsuarioOpen = () => {
    this.setState({ usuarioOpen: true });
  };

  handleUsuarioClose = () => {
    this.setState({ usuarioOpen: false });
  };

  handleRolesClose = () => {
    this.setState({ open: false });
  };

  handleCreateUserClose = () => {
    this.setState({ createUserOpen: false });
    this.props.getUsersAndRoles();
  };

  handleConfirmOpen = (confirmAcion, confirmMessage) => {
    this.setState({
      confirmOpen: true,
      confirmAcion,
      confirmMessage,
    });
  };

  handleConfirmClose = (confirm) => {
    const { confirmAcion } = this.state;
    if (confirm) {
      confirmAcion();
    }
    this.setState({ confirmOpen: false });
  };

  deleteUserAuth = (uid) => {
    const { enqueueSnackbar } = this.props;
    this.setState({ deleteLoading: true });
    functions
      .deleteUser(uid)
      .then(() => {
        this.setState({ deleteLoading: false });
        enqueueSnackbar('Usuário excluído com sucesso!', { variant: 'success' });
        this.props.getUsersAndRoles();
      })
      .catch((error) => {
        this.setState({ deleteLoading: false });
        enqueueSnackbar(`Falha ao excluir usuário: ${error.message}`, { variant: 'error' });
      });
  };

  handleOpenDialog = () => {
    this.setState({ openDialog: true });
  };

  handleCloseDialog = () => {
    this.setState({ openDialog: false });
  };

  render() {
    const { classes, users, loading, authUser } = this.props;
    const { confirmMessage, confirmOpen, deleteLoading } = this.state;
    const canDelete = authUser?.roles?.includes('gestor_sistema');
    return (
      <React.Fragment>
        <SindilegisBar />
        <Loading loading={loading} />
        {!loading ? (
          <>
            {deleteLoading ? (
              <Grid
                container
                style={{
                  display: 'flex',
                  height: '100vh',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}>
                <Grid item>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                    }}>
                    <CircularProgress />
                    <Typography
                      variant="body1"
                      style={{
                        marginTop: 16,
                      }}>
                      Excluindo usuário
                    </Typography>
                  </div>
                </Grid>
              </Grid>
            ) : (
              <Container maxWidth="xl">
                <Grid
                  container
                  spacing={2}
                  justifyContent="center"
                  alignItems="center"
                  className={classes.areaOperacoes}>
                  <Grid container item md={12} justifyContent="flex-end" spacing={2}>
                    <Grid item>
                      <ButtomComponent variant="insert" onClick={() => this.handleCreateUserOpen()}>
                        Cadastrar novo usuário
                      </ButtomComponent>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container item sm={12} justifyContent="center" alignItems="center">
                  <MaterialTable
                    style={{ width: 'calc(100% - 32px)', marginTop: 10 }}
                    columns={[
                      { title: 'Nome Completo', field: 'displayName' },
                      { title: 'Email', field: 'email' },
                      {
                        title: 'Perfis',
                        field: 'roles',
                        render: (row) =>
                          row.roles && row.roles.map((r) => rolesDescription[r]).join(', '),
                      },
                    ]}
                    data={users}
                    title="Usuários"
                    actions={[
                      {
                        icon: 'lock',
                        tooltip: 'Editar perfis',
                        iconProps: {
                          fontSize: 'small',
                        },
                        onClick: (_, row) => this.props.setUserId(row.id) && this.handleRolesOpen(),
                      },
                      {
                        icon: 'person',
                        tooltip: 'Editar usuário',
                        iconProps: {
                          fontSize: 'small',
                        },
                        onClick: (_, row) =>
                          this.props.setUserId(row.id) && this.handleUsuarioOpen(),
                      },
                      canDelete
                        ? {
                            icon: 'delete',
                            tooltip: 'Excluir usuário',
                            iconProps: {
                              fontSize: 'small',
                            },
                            onClick: (event, row) => {
                              this.handleConfirmOpen(
                                () => this.deleteUserAuth(row.id),
                                <span>
                                  Confirma a exclusão permanente do usuário{' '}
                                  <strong>{row?.displayName}</strong>?
                                </span>
                              );
                            },
                          }
                        : null,
                    ]}
                    options={{
                      actionsColumnIndex: -1,
                    }}
                    localization={tableI18n}
                    detailPanel={(row) => this.renderDetail(row)}
                    onRowClick={(event, rowData, togglePanel) => togglePanel()}
                  />
                </Grid>
                <EditarPerfis open={this.state.open} handleClose={this.handleRolesClose} />
                <CreateUser
                  open={this.state.createUserOpen}
                  handleClose={this.handleCreateUserClose}
                />
                <Usuario
                  open={this.state.usuarioOpen}
                  handleClose={this.handleUsuarioClose}
                  email={this.props?.authUser?.email}
                  canEdit={canDelete}
                />
                <ConfirmDialog
                  open={confirmOpen}
                  message={confirmMessage}
                  onClose={this.handleConfirmClose}
                />
              </Container>
            )}
          </>
        ) : null}
      </React.Fragment>
    );
  }

  renderDetail(usuario) {
    const { classes } = this.props;
    const logs = usuario.logs || {};
    const sortedLogs = Object.values(logs).sort((a, b) => b.date - a.date);

    const logsToDisplay = sortedLogs.slice(0, 4);
    const remainingLogs = sortedLogs.slice(4);

    return (
      <div>
        {logsToDisplay.length > 0 && (
          <Grid container spacing={2} style={{ padding: 8 }} className={classes.detail}>
            {logsToDisplay.map((log, index) => {
              return (
                <CustomCard
                  key={index}
                  smSize={3}
                  subheader={'Log registrado'}
                  cardContentHeight="150px"
                  dataFields={[
                    { label: 'Ação', value: log?.type },
                    log.data
                      ? { label: 'Info', value: log.data }
                      : { label: 'Arquivo', value: log.filename },
                    { label: 'Data', value: formataData(log?.date) },
                  ]}
                />
              );
            })}
          </Grid>
        )}

        {remainingLogs.length > 0 && (
          <ButtonComponent onClick={this.handleOpenDialog}>Ver mais logs</ButtonComponent>
        )}

        <Dialog
          open={this.state.openDialog}
          onClose={this.handleCloseDialog}
          maxWidth="md"
          fullWidth>
          <DialogTitle>{usuario.displayName || usuario.display_name}</DialogTitle>
          <DialogContent>
            <Grid container spacing={2}>
              {remainingLogs.map((log, index) => (
                <CustomCard
                  key={index}
                  smSize={3}
                  subheader={'Log registrado'}
                  cardContentHeight="150px"
                  dataFields={[
                    { label: 'Ação', value: log?.type },
                    log.data
                      ? { label: 'Info', value: log.data }
                      : { label: 'Arquivo', value: log.filename },
                    { label: 'Data', value: formataData(log?.date) },
                  ]}
                />
              ))}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCloseDialog} color="primary">
              Fechar
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = ({ userState: { users, loading } }) => ({
  users,
  loading,
});

Roles.propTypes = {
  classes: PropTypes.object.isRequired,
};

const enhance = compose(
  withStyles(styles),
  connect(mapStateToProps, { getUsersAndRoles, setUserId }),
  reduxForm({ form: 'users', enableReinitialize: true }),
  withRouter,
  withSnackbar
);

export default enhance(Roles);
