import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import * as routes from '../../constants/routes';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import { withStyles } from '@material-ui/core/styles';
import MaterialTable from 'material-table';
import * as roles from '../../constants/roles';
import { getFiliados } from '../../actions/filiado';
import tableI18n from '../TableLocalization';
import SindilegisBar from '../commons/SindilegisBar';
import styles from './styles';
import Loading from '../commons/Loading';
import { downloadXlsx, calcularIdade } from '../commons/util';
import { formataData, dateFieldSort } from '../Financial/util';
import { getRoutes } from '../commons/util';
import MenuButton from '../commons/MenuButton';

class RelatorioFiliados extends React.Component {
  componentDidMount() {
    this.props.getFiliados();
  }

  render() {
    const { classes, filiados, authUser, loading, location } = this.props;
    const canExport = _.includes(authUser.roles, roles.GESTOR_USUARIO);
    filiados.forEach((item) => {
      item.data_cadastro = formataData(item.data_cadastro);
      item.data_desfiliacao = formataData(item.data_desfiliacao);
      item.ultima_filiacao = formataData(item.ultima_filiacao)
      item.idade = calcularIdade(item.data_nascimento);
    });

    const currentPath = location.pathname;
    const buttonRoutes = getRoutes(routes, currentPath);
    return (
      <React.Fragment>
        <SindilegisBar />
        <Loading loading={loading} />
        {!loading && (
          <Container maxWidth="xl">
            <Grid
              container
              spacing={2}
              justifyContent="center"
              alignItems="center"
              className={classes.areaOperacoes}>
              <Grid container item md={12} justifyContent="flex-end" spacing={2}>
                <MenuButton buttonsRoutes={buttonRoutes} location={location} />
              </Grid>
            </Grid>
            <Grid container item sm={12} spacing={2} justifyContent="center" alignItems="center">
              <Grid item xs={12} md={12}>
                <div className={classes.demo}>
                  <MaterialTable
                    columns={[
                      {
                        title: 'Matrícula/Ponto',
                        field: 'matricula',
                        render: (rowData) => (rowData.ponto ? rowData.ponto : rowData.matricula),
                      },
                      {
                        title: 'Nome Completo', field: 'nome_completo',
                        cellStyle: {
                          whiteSpace: 'nowrap'
                        }
                      },
                      { title: 'Email', field: 'email' },
                      {
                        title: 'Cargo', field: 'cargo'
                      },
                      {
                        title: 'CPF', field: 'cpf', cellStyle: {
                          whiteSpace: 'nowrap'
                        }
                      },
                      {
                        title: 'Data de nascimento',
                        field: 'data_nascimento',
                        customSort: (a, b) => dateFieldSort(a, b, 'data_nascimento'),
                      },
                      { title: 'Idade', field: 'idade' },
                      { title: 'Sexo', field: 'sexo' },
                      { title: 'Casa', field: 'empresa' },
                      {
                        title: 'CEP', field: 'cep', cellStyle: {
                          whiteSpace: 'nowrap'
                        }
                      },
                      {
                        title: 'Endereço', field: 'logradouro'
                      },
                      {
                        title: 'Bairro', field: 'bairro', cellStyle: {
                          whiteSpace: 'nowrap'
                        }
                      },
                      { title: 'Cidade', field: 'cidade' },
                      { title: 'UF', field: 'estado' },
                      { title: 'Situação', field: 'situacao_filiado' },
                      {
                        /* nome_falecido é somente no db, o correto é o que está no title */
                      },
                      { title: 'Nome do servidor titular', field: 'nome_falecido' },
                      {
                        /* cpf_falecido é somente no db, o correto é o que está no title */
                      },
                      { title: 'CPF do titular', field: 'cpf_falecido' },
                      {
                        title: 'Celular', field: 'tel_celular', cellStyle: {
                          whiteSpace: 'nowrap'
                        }
                      },
                      {
                        title: 'Tel. comercial', field: 'tel_comercial', cellStyle: {
                          whiteSpace: 'nowrap'
                        }
                      },
                      {
                        title: 'Tel. residencial', field: 'tel_residencial', cellStyle: {
                          whiteSpace: 'nowrap'
                        }
                      },
                      { title: 'Forma de pagamento', field: 'forma_pagamento' },
                      {
                        title: 'Data de cadastro',
                        field: 'data_cadastro',
                        customSort: (a, b) => dateFieldSort(a, b, 'data_cadastro'),
                      },
                      {
                        title: 'Data de filiação',
                        field: 'data_filiacao',
                        customSort: (a, b) => dateFieldSort(a, b, 'data_filiacao'),
                      },
                      {
                        title: 'Última filiação',
                        field: 'ultima_filiacao',
                        customSort: (a, b) => dateFieldSort(a, b, 'ultima_filiacao'),
                        render: (rowData) => rowData.ultima_filiacao ? rowData.ultima_filiacao : rowData.data_cadastro ? rowData.data_cadastro : [],
                      },
                      {
                        title: 'Última desfiliação',
                        field: 'data_desfiliacao',
                        customSort: (a, b) => dateFieldSort(a, b, 'data_desfiliacao'),
                      },
                      {
                        title: 'Cadastro',
                        field: 'cadastro',
                        lookup: { FILIADO: 'Filiado', INATIVO: 'Desfiliado' },
                      },
                    ]}
                    data={filiados}
                    title="Relatório de Filiados"
                    localization={tableI18n}
                    options={{
                      filtering: true,
                      exportButton: canExport,
                      exportAllData: true,
                      exportDelimiter: ';',
                      exportFileName: 'relatorio de filiados',
                      exportCsv: (columns, data) => {
                        const processedData = data.map((row) => {
                          return {
                            ...row,
                            matricula: row.ponto || row.matricula,
                          };
                        });
                        downloadXlsx(columns, processedData, 'relatorio_filiados.xlsx');
                      },
                      padding: 'dense',
                      pageSize: 10,
                      pageSizeOptions: [10, 20, 50, 100],
                    }}
                    icons={{ Filter: () => <span></span> }}
                  />
                </div>
              </Grid>
            </Grid>
          </Container>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ filiado: { filiados, loading } }) => ({ filiados, loading });

RelatorioFiliados.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, { getFiliados })
)(RelatorioFiliados);
