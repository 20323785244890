import React, { useEffect } from 'react';
import { Field, reduxForm } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import InputText from '../commons/InputText';
import DatePicker from '../commons/DatePicker';
import { withSnackbar } from 'notistack';
import { storage } from '../../firebase';
import styles from './styles';
import AnexoDocumento from './anexo_documento';
import {
  getDocumento,
  criarDocumento,
  saveDocumento,
  setDocumentoId,
} from '../../actions/documento';

const DocumentoForm = ({
  open,
  handleClose,
  handleSubmit,
  pristine,
  submitting,
  reset,
  email,
  enqueueSnackbar,
}) => {
  const dispatch = useDispatch();
  const documento = useSelector((state) => state.documento.documento);
  const documentoId = useSelector((state) => state.documento.documentoId);

  useEffect(() => {
    if (documentoId) {
      dispatch(getDocumento(documentoId));
    }
  }, [documentoId, dispatch]);

  const save = (documentoData) => {
    if (documentoId) {
      dispatch(saveDocumento(documentoId, documentoData, successHandler));
    } else {
      dispatch(
        criarDocumento(documentoData, (key) => {
          dispatch(setDocumentoId(key));
          dispatch(getDocumento(key));
          successHandler();
        })
      );
    }
  };

  const deleteAnexo = (anexo) => {
    dispatch(saveDocumento(documentoId, { anexo: null }));
    storage.removerAnexo(anexo.url);
  };

  const successHandler = () => {
    enqueueSnackbar('Documento salvo com sucesso!', { variant: 'success' });
  };

  return (
    <Dialog
      open={open}
      onClose={() => handleClose(null)}
      fullWidth
      maxWidth="sm"
      aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Cadastrar documento</DialogTitle>
      <form onSubmit={handleSubmit(save)}>
        <DialogContent>
          <Grid container spacing={2} hidden={false} alignItems="center" justifyContent="center">
            <Grid item sm={12}>
              <Field name="nome" component={InputText} label="Nome" />
            </Grid>
            <Grid item sm={6}>
              <Field
                name="data_inicio_vigencia"
                component={DatePicker}
                label="Data início vigência"
              />
            </Grid>
            <Grid item sm={6}>
              <Field name="data_fim_vigencia" component={DatePicker} label="Data fim vigência" />
            </Grid>
            <Grid item sm={12}>
              {documento && (
                <AnexoDocumento
                  email={email}
                  id={documentoId}
                  anexo={documento.anexo}
                  handleDelete={(anexo) => deleteAnexo(anexo)}
                />
              )}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              reset();
              handleClose(true);
            }}
            color="default">
            Fechar
          </Button>
          <Button
            variant="contained"
            type="submit"
            disabled={pristine || submitting}
            color="primary">
            Salvar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

DocumentoForm.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  reset: PropTypes.func.isRequired,
  email: PropTypes.string.isRequired,
  enqueueSnackbar: PropTypes.func.isRequired,
};

const mapStateToProps = ({ documento: { documento, documentoId } }) => ({
  documento,
  documentoId,
  initialValues: documento,
});

const validate = (values) => {
  const errors = {};
  const requiredFields = ['nome', 'url', 'data_inicio_vigencia', 'data_fim_vigencia'];
  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = ' Obrigatório';
    }
  });
  return errors;
};

export default compose(
  connect(mapStateToProps, { getDocumento, criarDocumento, saveDocumento, setDocumentoId }),
  withStyles(styles)
)(
  reduxForm({
    form: 'documento',
    validate,
    enableReinitialize: true,
  })(withSnackbar(DocumentoForm))
);
