import {
  BENS_SET,
  BEM_SET,
  BEM_ID_SET,
  BENS_REQUEST,
  BEM_UPDATED,
  FLUXOS_REQUEST,
  FLUXOS_SET,
  FLUXO_ID_SET,
  FLUXO_SET,
  FLUXO_UPDATED,
  HISTS_MANUTENCAO_SET,
  HIST_MANUTENCAO_SET,
  HIST_MANUTENCAO_ID_SET,
  HISTS_MANUTENCAO_REQUEST,
  HIST_MANUTENCAO_UPDATED,
  HISTORICO_BEM_SET,
  HISTORICO_FLUXO_SET,
  HISTORICO_MANUTENCAO_SET,
} from './types';
import { db } from '../firebase';
import _ from 'lodash';

export const saveBemPatrimonio = (id, data, callback) => {
  return (dispatch) => {
    return db.doSaveBemPatrimonio(id, data).then(() => {
      dispatch({ type: BEM_UPDATED, payload: { id, data } });
      callback && callback();
    });
  };
};

export const saveFluxo = (id, data, callback) => {
  return (dispatch) => {
    return db.doSaveFluxo(id, data).then(() => {
      dispatch({ type: FLUXO_UPDATED, payload: { id, data } });
      callback && callback();
    });
  };
};

export const saveHistManutencao = (id, data, callback) => {
  return (dispatch) => {
    return db.doSaveHistManutencao(id, data).then(() => {
      dispatch({ type: HIST_MANUTENCAO_UPDATED, payload: { id, data } });
      callback && callback();
    });
  };
};

export const criarBemPatrimonio = (data, callback) => {
  return (dispatch) => {
    data.data_cadastro = new Date().getTime();

    db.doCreateBemPatrimonio(data)
      .then((ref) => {
        callback && callback(ref.key);
      })
      .catch((error) => {
        console.error('Erro ao criar bem patrimonial:', error);
      });
  };
};

export const criarFluxo = (data, callback) => {
  return (dispatch) => {
    db.doCreateFluxo(data)
      .then((ref) => {
        callback && callback(ref.key);
      })
      .catch((error) => {
        console.error('Erro ao criar fluxo:', error);
      });
  };
};

export const criarHistManutencao = (data, callback) => {
  return (dispatch) => {
    db.doCreateHistManutencao(data)
      .then((ref) => {
        callback && callback(ref.key);
      })
      .catch((error) => {
        console.error('Erro ao criar histórico de manutenção:', error);
      });
  };
};

export const getBensPatrimonio = () => {
  return (dispatch) => {
    dispatch({ type: BENS_REQUEST });
    db.onceGetBensPatrimonio().then((snapshot) => {
      let bens = snapshot.val();
      if (bens) {
        Object.keys(bens).map((id) => {
          bens[id].id = id;
          return bens[id];
        });
      } else {
        bens = [];
      }
      dispatch({ type: BENS_SET, bens });
    });
  };
};

export const getFluxos = () => {
  return (dispatch) => {
    dispatch({ type: FLUXOS_REQUEST });
    db.onceGetFluxos().then((snapshot) => {
      let fluxos = snapshot.val();
      if (fluxos) {
        Object.keys(fluxos).map((id) => {
          fluxos[id].id = id;
          return fluxos[id];
        });
      } else {
        fluxos = [];
      }
      dispatch({ type: FLUXOS_SET, fluxos });
    });
  };
};

export const getHistsManutencao = () => {
  return (dispatch) => {
    dispatch({ type: HISTS_MANUTENCAO_REQUEST });
    db.onceGetHistsManutencao().then((snapshot) => {
      let histsManutencao = snapshot.val();
      if (histsManutencao) {
        Object.keys(histsManutencao).map((id) => {
          histsManutencao[id].id = id;
          return histsManutencao[id];
        });
      } else {
        histsManutencao = [];
      }
      dispatch({ type: HISTS_MANUTENCAO_SET, histsManutencao });
    });
  };
};

export const getBem = (id) => {
  return (dispatch) => {
    db.onceGetBemPatrimonio(id).then((snapshot) => {
      const bem = snapshot.val();
      dispatch({ type: BEM_SET, bem });
    });
  };
};

export const getFluxo = (id) => {
  return (dispatch) => {
    db.onceGetFluxo(id).then((snapshot) => {
      const fluxo = snapshot.val();
      dispatch({ type: FLUXO_SET, fluxo });
    });
  };
};

export const getHistManutencao = (id) => {
  return (dispatch) => {
    db.onceGetHistManutencao(id).then((snapshot) => {
      const histManutencao = snapshot.val();
      dispatch({ type: HIST_MANUTENCAO_SET, histManutencao });
    });
  };
};

export const setBem = (bem) => (dispatch) => {
  dispatch({ type: BEM_SET, bem });
};

export const setBemId = (bemId) => (dispatch) => {
  dispatch({ type: BEM_ID_SET, bemId });
};

export const setFluxo = (fluxo) => (dispatch) => {
  dispatch({ type: FLUXO_SET, fluxo });
};

export const setFluxoId = (fluxoId) => (dispatch) => {
  dispatch({ type: FLUXO_ID_SET, fluxoId });
};

export const setHistManutencao = (histManutencao) => (dispatch) => {
  dispatch({ type: HIST_MANUTENCAO_SET, histManutencao });
};

export const setHistManutencaoId = (histManutencaoId) => (dispatch) => {
  dispatch({ type: HIST_MANUTENCAO_ID_SET, histManutencaoId });
};

export const incluirAnexoBem = (anexo, bemId) => {
  return async () => {
    try {
      return await db.doIncluirAnexoBem(anexo, bemId);
    } catch (error) {
      throw error;
    }
  };
};

export const removerAnexoBem = (anexoId, bemId) => {
  return async () => {
    try {
      return await db.doRemoverAnexoBem(anexoId, bemId);
    } catch (error) {
      throw error;
    }
  };
};

export const getHistoricoBem = (id) => {
  return async (dispatch) => {
    try {
      const snapshot = await db.onceGetHistoricoBem(id);
      let historico = snapshot.val();

      if (historico) {
        Object.keys(historico).forEach((key) => {
          historico[key].data = parseInt(key, 10);
        });
        historico = _.values(historico).reverse();
      } else {
        historico = [];
      }

      dispatch({
        type: HISTORICO_BEM_SET,
        historico,
      });

      return historico;
    } catch (error) {
      console.error('Erro ao carregar o histórico:', error);
      dispatch({
        type: 'HISTORICO_ERROR',
        error: error.message,
      });
    }
  };
};

export const setHistoricoBem = (historico) => (dispatch) =>
  dispatch({ type: HISTORICO_BEM_SET, historico });

export const getHistoricoFluxo = (id) => {
  return async (dispatch) => {
    try {
      const snapshot = await db.onceGetHistoricoFluxo(id);
      let historico = snapshot.val();

      if (historico) {
        Object.keys(historico).forEach((key) => {
          historico[key].data = parseInt(key, 10);
        });
        historico = _.values(historico).reverse();
      } else {
        historico = [];
      }

      dispatch({
        type: HISTORICO_FLUXO_SET,
        historico,
      });

      return historico;
    } catch (error) {
      console.error('Erro ao carregar o histórico:', error);
      dispatch({
        type: 'HISTORICO_ERROR',
        error: error.message,
      });
    }
  };
};

export const setHistoricoFluxo = (historico) => (dispatch) =>
  dispatch({ type: HISTORICO_FLUXO_SET, historico });

export const getHistoricoManutencao = (id) => {
  return async (dispatch) => {
    try {
      const snapshot = await db.onceGetHistoricoManutencao(id);
      let historico = snapshot.val();

      if (historico) {
        Object.keys(historico).forEach((key) => {
          historico[key].data = parseInt(key, 10);
        });
        historico = _.values(historico).reverse();
      } else {
        historico = [];
      }

      dispatch({
        type: HISTORICO_MANUTENCAO_SET,
        historico,
      });

      return historico;
    } catch (error) {
      console.error('Erro ao carregar o histórico:', error);
      dispatch({
        type: 'HISTORICO_ERROR',
        error: error.message,
      });
    }
  };
};

export const setHistoricoManutencao = (historico) => (dispatch) =>
  dispatch({ type: HISTORICO_MANUTENCAO_SET, historico });
