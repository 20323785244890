import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import AddIcon from '@material-ui/icons/Add';
import Container from '@material-ui/core/Container';
import Fab from '@material-ui/core/Fab';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import MaterialTable from 'material-table';
import { formataData } from '../Financial/util';
import {
  getFiliados,
  setFiliadoId,
  setFiliado,
  saveFiliado,
  deleteFiliado,
  removerAnexoFiliado,
} from '../../actions/filiado';
import * as roles from '../../constants/roles';
import * as routes from '../../constants/routes';
import tableI18n from '../TableLocalization';
import UserDetail from '../UserDetail';
import ConfirmDialog from '../commons/ConfirmDialog';
import SindilegisBar from '../commons/SindilegisBar';
import styles from './styles';
import Loading from '../commons/Loading';
import DependentList from '../DependentList';
import { calcularIdade, getRoutes, matchFullName } from '../commons/util';
import ProcessoFiliadoList from '../ProcessoFiliadoList';
import {
  getFiliadosProcesso,
  getProcessos,
  SITUACAO_PROCESSO_EXCLUIDO,
} from '../../actions/juridico';
import MenuButton from '../commons/MenuButton';
import ArquivosFiliado from '../UserDetail/anexos/anexos';
import { storage } from '../../firebase';
import { withSnackbar } from 'notistack';
import { downloadXlsx } from '../commons/util';

class UserList extends React.Component {
  state = {
    open: false,
    dependentsOpen: false,
    processoOpen: false,
    confirmOpen: false,
    confirmAction: Function,
    confirmMessage: '',
    filiadoId: null,
    filiado: null,
    arquivosFiliadoOpen: false,
  };

  componentDidMount() {
    const { getFiliados, getProcessos, getFiliadosProcesso } = this.props;
    getFiliados();
    getProcessos();
    getFiliadosProcesso();
  }

  handleFiliadoClickOpen = () => {
    this.setState({ open: true });
  };

  handleFiliadoClose = (filiadoId) => {
    const { setFiliado, setFiliadoId } = this.props;
    setFiliado(null);
    setFiliadoId(null);
    this.setState({ open: false });
    if (filiadoId) {
      this.reloadFiliados();
    }
  };

  handleDependentsOpen = () => {
    this.setState({ dependentsOpen: true });
  };

  handleDependentsClose = () => {
    this.setState({ dependentsOpen: false });
  };

  handleProcessosOpen = () => {
    const { getProcessos } = this.props;
    getProcessos();
    this.setState({ processoOpen: true });
  };

  handleProcessosClose = () => {
    this.setState({ processoOpen: false });
  };

  handleConfirmOpen = (confirmAction, confirmMessage) => {
    this.setState({ confirmOpen: true, confirmAction, confirmMessage });
  };

  handleConfirmClose = (confirm) => {
    const { confirmAction } = this.state;
    if (confirm) {
      confirmAction();
      this.setState({ confirmOpen: false });
    } else {
      this.setState({ confirmOpen: false });
    }
  };

  reloadFiliados() {
    const { getFiliados } = this.props;
    getFiliados();
  }

  handleArquivosFiliadoOpen = () => {
    this.setState({ arquivosFiliadoOpen: true });
  };

  handleArquivosFiliadoClose = () => {
    const { setFiliado, setFiliadoId } = this.props;
    setFiliado(null);
    setFiliadoId(null);
    this.setState({ arquivosFiliadoOpen: false });
    this.reloadFiliados();
  };

  handleDeleteArquivoFiliado = (anexoId, filiadoId, url) => {
    const { removerAnexoFiliado, enqueueSnackbar } = this.props;
    return new Promise((resolve, reject) => {
      this.handleConfirmOpen(() => {
        removerAnexoFiliado(anexoId, filiadoId)
          .then(() => {
            storage.removerAnexo(url);
            enqueueSnackbar('Arquivo excluído com sucesso', { variant: 'success' });
            resolve();
          })
          .catch((error) => {
            enqueueSnackbar('Erro ao excluir arquivo', { variant: 'error' });
            reject(error);
          });
      }, 'Confima a exclusão do arquivo?');
    });
  };

  render() {
    const {
      classes,
      filiados,
      authUser,
      loading,
      location,
      setFiliado,
      setFiliadoId,
      deleteFiliado,
      saveFiliado,
    } = this.props;
    const { arquivosFiliadoOpen, dependentsOpen, confirmMessage, confirmOpen, processoOpen } =
      this.state;
    const canEdit = _.includes(authUser.roles, roles.GESTOR_USUARIO);
    const canExport = _.includes(authUser.roles, roles.GESTOR_SISTEMA);
    const isEditor = _.includes(authUser.roles, roles.EDITOR_FILIADO);
    const currentPath = location.pathname;
    const buttonRoutes = getRoutes(routes, currentPath);

    return (
      <React.Fragment>
        <SindilegisBar />
        <Loading loading={loading} />
        {!loading && (
          <Container maxWidth="xl">
            <MenuButton buttonsRoutes={buttonRoutes} location={location} />
            <Grid item xs={12} md={12}>
              <Typography variant="h6" className={classes.title} />
              <div className={classes.demo}>
                <MaterialTable
                  columns={[
                    {
                      title: 'Matrícula',
                      field: 'matricula',
                      render: (rowdata) => (rowdata.ponto ? rowdata.ponto : rowdata.matricula),
                    },
                    {
                      title: 'Nome Completo',
                      field: 'nome_completo',
                      customFilterAndSearch: (term, row) => matchFullName(term, row.nome_completo),
                      render: (rowData) => {
                        return (
                          <span style={rowData.data_desfiliacao ? { color: '#f44336' } : {}}>
                            {rowData.nome_completo.toUpperCase()}
                          </span>
                        );
                      },
                      cellStyle: {
                        whiteSpace: 'nowrap',
                      },
                    },
                    {
                      title: 'CPF',
                      field: 'cpf',
                      cellStyle: {
                        whiteSpace: 'nowrap',
                      },
                    },
                    { title: 'Email', field: 'email' },
                    { title: 'Casa', field: 'empresa' },
                    { title: 'Situação', field: 'situacao_filiado' },
                    {
                      title: 'Celular',
                      field: 'tel_celular',
                      cellStyle: {
                        whiteSpace: 'nowrap',
                      },
                    },
                    {
                      title: 'Idade',
                      field: 'data_nascimento',
                      render: (row) => calcularIdade(row.data_nascimento),
                    },
                    { title: 'Filiação', field: 'data_filiacao' },
                    {
                      title: 'Desfiliação',
                      field: 'data_desfiliacao',
                      render: (row) => formataData(row.data_desfiliacao),
                    },
                  ]}
                  data={filiados}
                  title="Filiados"
                  actions={[
                    {
                      icon: canEdit || isEditor ? 'edit' : 'info',
                      tooltip: canEdit || isEditor ? 'Editar' : 'Detalhe',
                      onClick: (event, row) =>
                        setFiliadoId(row.id) && setFiliado(row) && this.handleFiliadoClickOpen(),
                    },
                    (row) => ({
                      icon: 'balance',
                      tooltip: this.ifHasProcesso(row) ? 'PROCESSOS' : 'Sem Processos',
                      iconProps: {
                        style: {
                          color: this.ifHasProcesso(row) ? 'green' : 'whitesmoke',
                        },
                      },
                      onClick: (event, row) => {
                        if (this.ifHasProcesso(row)) {
                          setFiliadoId(row.id);
                          this.handleProcessosOpen(row.id);
                        }
                      },
                    }),
                    {
                      icon: 'supervisor_account',
                      tooltip: 'Dependentes',
                      onClick: (event, row) => setFiliadoId(row.id) && this.handleDependentsOpen(),
                    },
                    canEdit
                      ? {
                        icon: 'delete',
                        tooltip: 'Excluir',
                        onClick: (event, row) =>
                          this.handleConfirmOpen(
                            () => deleteFiliado(row.id),
                            `Confirma a exclusão permanente de ${row?.nome_completo}?`
                          ),
                      }
                      : null,
                    (rowData) => {
                      if (!canEdit) {
                        return null;
                      } else {
                        return !rowData.data_desfiliacao
                          ? {
                            icon: 'remove',
                            disabled: !canEdit,
                            iconProps: {
                              color: 'error',
                            },
                            tooltip: 'Remover filiação',
                            onClick: (event, row) => {
                              this.handleConfirmOpen(
                                () => this.removeFiliado(row),
                                `Confirma exclusão do cadastro de ${row?.nome_completo}?`
                              );
                            },
                          }
                          : {
                            icon: 'add',
                            disabled: !canEdit,
                            iconProps: {
                              color: 'primary',
                            },
                            tooltip: 'Ativar',
                            onClick: (event, row) => {
                              rowData.data_desfiliacao = '';
                              saveFiliado(row.id, {
                                data_desfiliacao: rowData.data_desfiliacao,
                                ultima_filiacao: new Date().getTime()
                              });
                              this.reloadFiliados();
                            },
                          };
                      }
                    },
                    (rowData) =>
                      rowData.anexos
                        ? {
                          icon: 'folder',
                          tooltip: 'Documentos',
                          onClick: (event, row) =>
                            setFiliadoId(row.id) &&
                            setFiliado(row) &&
                            this.handleArquivosFiliadoOpen(),
                        }
                        : null,
                  ]}
                  options={{
                    actionsColumnIndex: -1,
                    exportButton: canExport,
                    exportFileName: 'filiados',
                    paging: true,
                    exportCsv: (columns, data) => {
                      const processedData = data.map((row) => {
                        return {
                          ...row,
                          matricula: row.ponto || row.matricula,
                        };
                      });
                      downloadXlsx(columns, processedData, 'filiados.xlsx');
                    },
                  }}
                  localization={tableI18n}
                />
                <Fab
                  color="primary"
                  aria-label="Cadastrar"
                  className={classes.fab}
                  onClick={() => {
                    setFiliadoId(null);
                    setFiliado(null);
                    this.handleFiliadoClickOpen();
                  }}>
                  <AddIcon />
                </Fab>
              </div>
            </Grid>
            <UserDetail
              open={this.state.open}
              handleClose={this.handleFiliadoClose}
              canEdit={canEdit || isEditor}
              filiado={this.state.filiado}
            />
            <DependentList
              open={dependentsOpen}
              handleClose={this.handleDependentsClose}
              canEdit={canEdit || isEditor}
            />
            <ConfirmDialog
              open={confirmOpen}
              message={confirmMessage}
              onClose={this.handleConfirmClose}
            />
            <ProcessoFiliadoList
              open={processoOpen}
              handleClose={this.handleProcessosClose}
              canEdit={canEdit || isEditor}
            />
            <ArquivosFiliado
              open={arquivosFiliadoOpen}
              handleClose={this.handleArquivosFiliadoClose}
              handleDeleteArquivoFiliado={this.handleDeleteArquivoFiliado}
            />
          </Container>
        )}
      </React.Fragment>
    );
  }

  ifHasProcesso(row) {
    const { filiadosProcesso } = this.props;
    const relacionamento = filiadosProcesso ? Object.values(filiadosProcesso) : [];
    const filiadoId = row.id;
    let hasProcesso = false;

    if (relacionamento && relacionamento.length > 0) {
      for (let i = 0; i < relacionamento.length; i++) {
        const processos = Object.values(relacionamento[i]);
        let allExcluidos = true;

        for (let j = 0; j < processos.length; j++) {
          const processo = processos[j];
          if (processo.filiadoId === filiadoId) {
            if (processo.situacao_processo === SITUACAO_PROCESSO_EXCLUIDO) {
              continue;
            } else {
              allExcluidos = false;
              break;
            }
          }
        }

        if (!allExcluidos) {
          hasProcesso = true;
          break;
        }
      }
    }
    return hasProcesso;
  }

  removeFiliado(row) {
    const { saveFiliado } = this.props;
    row.data_desfiliacao = new Date().getTime();
    saveFiliado(row.id, { data_desfiliacao: row.data_desfiliacao });
  }
}

const mapStateToProps = ({
  filiado: { filiados, loading },
  filiados_processos: { filiadosProcesso },
}) => ({
  loading,
  filiados,
  filiadosProcesso,
});

UserList.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    getFiliados,
    setFiliadoId,
    setFiliado,
    saveFiliado,
    deleteFiliado,
    getProcessos,
    getFiliadosProcesso,
    removerAnexoFiliado,
  })
)(withSnackbar(withRouter(UserList)));
