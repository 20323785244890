import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import MaterialTable from 'material-table';
import {
  getObjetosProcesso,
  setObjetoProcessoId,
  setObjetoProcesso,
  deleteObjetoProcesso,
} from '../../../actions/objetoProcesso';
import tableI18n from '../../TableLocalization';
import ObjetoProcessoForm from './objeto';
import ConfirmDialog from '../../commons/ConfirmDialog';
import SindilegisBar from '../../commons/SindilegisBar';
import Loading from '../../commons/Loading';
import ButtonComponent from '../../commons/ButtonComponent';
import * as routes from '../../../constants/routes';
import MenuButton from '../../commons/MenuButton';
import styles from './styles'

const useStyles = makeStyles(styles);

const ObjetosProcesso = ({ authUser }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();

  const { objetosProcesso, loading } = useSelector(state => state.objetoProcesso);

  const [open, setOpen] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [confirmAction, setConfirmAction] = useState(() => Function);
  const [confirmMessage, setConfirmMessage] = useState('');

  useEffect(() => {
    dispatch(getObjetosProcesso());
  }, [dispatch]);

  const handleObjetoProcessoClickOpen = () => {
    setOpen(true);
  };

  const handleObjetoProcessoClose = (reload) => {
    setOpen(false);
    dispatch(setObjetoProcesso(null));
    dispatch(setObjetoProcessoId(null));
    if (reload) {
      reloadObjetosProcesso();
    }
  };

  const handleConfirmOpen = (confirmAction, confirmMessage) => {
    setConfirmOpen(true);
    setConfirmAction(() => confirmAction);
    setConfirmMessage(confirmMessage);
  };

  const handleConfirmClose = (confirm) => {
    if (confirm) {
      confirmAction();
    }
    setConfirmOpen(false);
  };

  const reloadObjetosProcesso = () => {
    dispatch(getObjetosProcesso());
  };

  const removeObjetoProcesso = (id) => {
    dispatch(deleteObjetoProcesso(id));
  };

  const isAuth = Boolean(authUser);
  const BUTTON_ROUTES = [
    { label: 'Jurídico', route: routes.JURIDICO },
    { label: 'Gerenciar processos', route: routes.JURIDICO_PROCESSOS },
    { label: 'Relatório de processos', route: routes.JURIDICO_PROCESSOS_RELATORIO },
  ];

  const CONDITIONAL_BUTTON = [
    { label: 'Escritórios', route: routes.ESCRITORIOS, condition: isAuth },
  ];

  return (
    <div className={classes.root}>
      <SindilegisBar />
      <Loading loading={loading} />
      <Container maxWidth="xl">
        <MenuButton
          buttonsRoutes={BUTTON_ROUTES}
          location={location}
          additionalButtons={CONDITIONAL_BUTTON}
        />
        <Grid style={{ marginLeft: 10 }} container justifyContent="flex-end" spacing={1}>
          <Grid item>
            <ButtonComponent
              variant="insert"
              onClick={handleObjetoProcessoClickOpen}>
              Cadastrar objeto de processo
            </ButtonComponent>
          </Grid>
        </Grid>
        <Grid item xs={12} md={12}>
          <Typography variant="h6" className={classes.title} />
          <div className={classes.demo}>
            <MaterialTable
              columns={[
                { title: 'Descrição', field: 'descricao' },
              ]}
              data={objetosProcesso || []}
              title="Objetos de Processo"
              actions={[
                {
                  icon: 'edit',
                  tooltip: 'Editar',
                  onClick: (event, row) => {
                    dispatch(setObjetoProcessoId(row.id));
                    handleObjetoProcessoClickOpen();
                  },
                },
                {
                  icon: 'delete',
                  tooltip: 'Excluir',
                  onClick: (event, row) =>
                    handleConfirmOpen(
                      () => removeObjetoProcesso(row.id),
                      'Confirma a exclusão permanente do objeto de processo?'
                    ),
                },
              ]}
              options={{
                actionsColumnIndex: -1,
                pageSize: 10,
                pageSizeOptions: [10, 20, 50, 100],
                exportButton: true,
                exportAllData: true,
              }}
              localization={tableI18n}
            />
          </div>
        </Grid>
        <ObjetoProcessoForm open={open} handleClose={handleObjetoProcessoClose} />
        <ConfirmDialog
          open={confirmOpen}
          message={confirmMessage}
          onClose={handleConfirmClose}
        />
      </Container>
    </div>
  );
};

ObjetosProcesso.propTypes = {
  authUser: PropTypes.object,
};

export default ObjetosProcesso;
