import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import AttachFile from '@material-ui/icons/AttachFile';
import LinearProgress from '@material-ui/core/LinearProgress';
import { uploadAnexoFiliado } from '../../../firebase/storage';
import { withSnackbar } from 'notistack';
import Tooltip from '@material-ui/core/Tooltip';
import FolderIcon from '@material-ui/icons/Folder';
import { Typography } from '@material-ui/core';
import ArquivosFiliado from './anexos';
import { getFiliado, removerAnexoFiliado, incluirAnexoFiliado } from '../../../actions/filiado';
import { storage } from '../../../firebase';
import ConfirmDialog from '../../commons/ConfirmDialog';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogButton from '@material-ui/core/Button';

const styles = (theme) => ({
  input: {
    display: 'none',
  },
  progress: {
    width: '100%',
    marginTop: theme.spacing(2),
  },
  previewContainer: {
    marginTop: theme.spacing(2),
    textAlign: 'center',
  },
  previewImage: {
    maxWidth: '100%',
    maxHeight: 200,
  },
  fileInfo: {
    marginTop: theme.spacing(1),
    fontSize: '0.9rem',
    color: theme.palette.text.secondary,
  },
});

class AnexoFiliado extends Component {
  state = {
    anexo: this.props?.anexo || [],
    uploadProgress: 0,
    arquivosFiliadoOpen: false,
    confirmOpen: false,
    confirmAction: Function,
    confirmMessage: '',
    confirmDialogOpen: false,
    selectedFile: null,
    confirmButton: 'Confirmar',
  };

  handleCapture = (event) => {
    const file = event.target.files[0];
    if (!file) return;
    this.setState({
      selectedFile: file,
      confirmDialogOpen: true,
    });
  };

  handleConfirmUpload = () => {
    const { filiadoId, incluirAnexoFiliado, enqueueSnackbar, onChange, getFiliado } = this.props;
    const { selectedFile } = this.state;

    const anexo = { selectedFile };

    if (!filiadoId) {
      this.setState({
        uploadProgress: 0,
        confirmDialogOpen: false,
        confirmButton: 'Confirmar',
      });

      enqueueSnackbar('Arquivo carregado com sucesso!', { variant: 'success' });

      onChange([anexo]);
      return;
    }

    this.setState({ confirmButton: 'Carregando...' });
    const uploadTask = uploadAnexoFiliado(`${filiadoId}/${selectedFile.name}`, selectedFile);

    uploadTask.on(
      'state_changed',
      (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        this.setState({ uploadProgress: progress });
      },
      (error) => {
        console.error('Erro ao fazer upload do anexo:', error);
      },
      () => {
        uploadTask.snapshot.ref.getMetadata().then((metadata) => {
          const anexo = { nome: selectedFile.name, url: metadata.fullPath };

          incluirAnexoFiliado(anexo, filiadoId).then(() => {
            this.setState({
              uploadProgress: 0,
              confirmDialogOpen: false,
              confirmButton: 'Confirmar',
            });

            enqueueSnackbar('Arquivo carregado com sucesso!', { variant: 'success' });
            getFiliado(filiadoId);
          });
        });
      }
    );
  };

  handleCancelUpload = () => {
    this.setState({
      confirmDialogOpen: false,
      selectedFile: null,
    });
  };

  handleArquivoFiliadoOpen = (filiadoId) => {
    this.setState({ arquivosFiliadoOpen: true, filiadoId });
  };

  handleArquivosFiliadosClose = () => {
    this.setState({ arquivosFiliadoOpen: false });
  };

  handleDeleteArquivoFiliado = (anexoId, filiadoId, url) => {
    const { removerAnexoFiliado, enqueueSnackbar } = this.props;
    const { removerAnexo } = storage;
    return new Promise((resolve, reject) => {
      this.handleConfirmOpen(() => {
        removerAnexoFiliado(anexoId, filiadoId)
          .then(() => {
            removerAnexo(url);
            enqueueSnackbar('Arquivo excluído com sucesso', { variant: 'success' });
            resolve();
          })
          .catch((error) => {
            enqueueSnackbar('Erro ao excluir arquivo', { variant: 'error' });
            reject(error);
          });
      }, 'Confirma a exclusão do arquivo?');
    });
  };

  handleConfirmClose = (confirm) => {
    const { confirmAction } = this.state;
    if (confirm) {
      confirmAction();
      this.setState({ confirmOpen: false });
    } else {
      this.setState({ confirmOpen: false });
    }
  };

  handleConfirmOpen = (confirmAction, confirmMessage) => {
    this.setState({ confirmOpen: true, confirmAction, confirmMessage });
  };

  render() {
    const { classes, filiadoId } = this.props;
    const {
      uploadProgress,
      anexo,
      arquivosFiliadoOpen,
      confirmOpen,
      confirmMessage,
      confirmDialogOpen,
      selectedFile,
      confirmButton,
    } = this.state;
    const anexoProps = this.props.anexo || [];
    const showArquivos = Object.values(anexo).length > 0 || Object.values(anexoProps).length > 0;

    return (
      <Fragment>
        <Grid>
          <input
            className={classes.input}
            id="upload-anexo-filiado"
            onChange={this.handleCapture}
            type="file"
          />
          <label htmlFor="upload-anexo-filiado">
            <Button color="primary" component="span" size="small" style={{ marginRight: 8 }}>
              <AttachFile />
              Anexar Documentos
            </Button>
          </label>
        </Grid>

        {showArquivos && (
          <Grid item sm={12} style={{ marginLeft: 8, marginBottom: 8, display: 'flex' }}>
            <Tooltip title="Arquivos">
              <Button
                color="primary"
                component="span"
                size="small"
                onClick={() => this.handleArquivoFiliadoOpen(filiadoId)}>
                <FolderIcon /> Ver arquivos
              </Button>
            </Tooltip>
          </Grid>
        )}
        <ArquivosFiliado
          open={arquivosFiliadoOpen}
          handleClose={this.handleArquivosFiliadosClose}
          handleDeleteArquivoFiliado={this.handleDeleteArquivoFiliado}
        />
        <ConfirmDialog
          open={confirmOpen}
          message={confirmMessage}
          onClose={this.handleConfirmClose}
        />
        <Dialog open={confirmDialogOpen} onClose={this.handleCancelUpload}>
          <DialogTitle>Confirmar Upload</DialogTitle>
          <DialogContent>
            <Typography variant="body1">
              Você deseja fazer o upload do arquivo: <strong>{selectedFile?.name}</strong>?
            </Typography>

            {uploadProgress > 0 && (
              <LinearProgress
                variant="determinate"
                value={uploadProgress}
                className={classes.progress}
              />
            )}
          </DialogContent>
          <DialogActions>
            <DialogButton onClick={this.handleCancelUpload} color="primary">
              Cancelar
            </DialogButton>
            <DialogButton onClick={this.handleConfirmUpload} color="primary">
              {confirmButton}
            </DialogButton>
          </DialogActions>
        </Dialog>
      </Fragment>
    );
  }
}

export default connect(null, { getFiliado, removerAnexoFiliado, incluirAnexoFiliado })(
  withStyles(styles, { withTheme: true })(withSnackbar(AnexoFiliado))
);
