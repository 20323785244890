import React, { useState, useEffect, useCallback } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Field, reduxForm, FieldArray } from 'redux-form';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  withStyles,
  MenuItem,
} from '@material-ui/core';
import { withSnackbar } from 'notistack';
import styles from './styles';
import InputText from '../commons/InputText';
import Select from '../commons/Select';
import * as config from './components/common/utils';
import DatePicker from '../commons/DatePicker';
import cnpj_contratados from './components/cnpjContratados';
import emails from './components/emails';
import AnexoContratosDocumentos from './anexos/anexoContratosDocumentos';
import {
  criarContrato,
  saveContrato,
  setContratoId,
  getContrato,
} from '../../actions/gestao_contratos';
import validate from './components/common/validate';
import { getBeneficiarios } from '../../actions/beneficiario';
import Loading from '../commons/Loading';

const DocumentoContratoForm = ({
  handleSubmit,
  pristine,
  reset,
  submitting,
  open,
  change,
  handleClose,
  enqueueSnackbar,
  by,
  initialValues,
  isOnlyView,
  nomeDocumento,
  situacaoDocumento,
  isEditing,
}) => {
  const dispatch = useDispatch();
  const [openAnexos, setOpenAnexos] = useState(false);
  const [anexosTemp, setAnexosTemp] = useState([]);
  const [isContrato, setIsContrato] = useState(false);

  const { beneficiarios } = useSelector((state) => state.beneficiario);
  const { contrato, contratoId, loading } = useSelector((state) => state.contratos);
  const hasContratoId = Boolean(contratoId);

  useEffect(() => {
    if (contratoId) {
      dispatch(getContrato(contratoId));
    }
    dispatch(getBeneficiarios());
  }, [contratoId, dispatch]);

  useEffect(() => {
    if (anexosTemp.length > 0) {
      dispatch(getContrato(contratoId));
    }
  }, [anexosTemp, contratoId, dispatch]);

  const save = useCallback(
    async (contrato) => {
      const validateContrato = (contrato) => {
        const cnpjIsEmpty = !contrato.cnpj_contratados || contrato.cnpj_contratados.length === 0;
        const emailsIsEmpty = !contrato.emails || contrato.emails.length === 0;
        const isInvalidDate = contrato.data_fim < contrato.data_inicio;
        const isInvalidDateAlert = contrato.data_alerta > contrato.data_fim;

        if (cnpjIsEmpty) {
          enqueueSnackbar('CNPJ do beneficiário é obrigatório!', { variant: 'error' });
          return false;
        }

        if (emailsIsEmpty) {
          enqueueSnackbar('Você precisa informar um email para alerta!', { variant: 'error' });
          return false;
        }

        if (isInvalidDate) {
          enqueueSnackbar('A data final não pode ser menor do que a data inicial!', {
            variant: 'error',
          });
          return false;
        }

        if (isInvalidDateAlert) {
          enqueueSnackbar(
            'A data para alerta não pode ser maior do que a data de fim do contrato!',
            { variant: 'error' }
          );
          return false;
        }
        return true;
      };

      contrato = { ...contrato, by };

      if ((isContrato || contrato.tipo_objeto === 'Contrato') && !validateContrato(contrato)) {
        return;
      }

      try {
        if (contratoId) {
          await dispatch(
            saveContrato(contratoId, contrato, () => {
              if (!contrato.anexos || contrato.anexos.length === 0) {
                enqueueSnackbar('Anexo é obrigatório!', { variant: 'error' });
                return;
              }
              enqueueSnackbar(`Edição salva com sucesso!`, { variant: 'success' });
              handleClose();
            })
          );
        } else {
          dispatch(
            criarContrato(contrato, (key) => {
              dispatch(setContratoId(key));
              dispatch(getContrato(key));
              enqueueSnackbar(
                `Contrato de número ${
                  contrato.numero_contrato
                } com a situação "${contrato.situacao.toUpperCase()}" salvo com sucesso!`,
                { variant: 'success' }
              );
            })
          );
        }
      } catch (error) {
        console.error('Erro ao salvar contrato:', error);
      }
    },
    [dispatch, contratoId, isContrato, by, enqueueSnackbar, handleClose]
  );

  const handleAnexosChange = useCallback(
    (anexosTemp) => {
      setAnexosTemp(anexosTemp);
      change('anexosFlag', new Date().getTime());
    },
    [change]
  );

  const handleSelectChange = useCallback((event) => {
    const selectedValue = event.target.value;
    setIsContrato(selectedValue === 'Contrato');
  }, []);

  const handleCancel = (contrato, isOnlyView) => {
    if (isOnlyView) {
      reset();
      handleClose(null);
      return;
    }

    if (contrato) {
      const anexos = contrato.anexos ? Object.values(contrato.anexos) : [];
      const anexosValidos = anexos.filter((anexo) => anexo.situacao === 'Ativo');
      if (!anexosValidos || anexosValidos.length === 0) {
        enqueueSnackbar('Anexo é obrigatório!', { variant: 'error' });
        return;
      }
    }
    reset();
    handleClose(null);
  };

  const handleCloseAnexos = () => {
    setOpenAnexos(false);
  };
  const showContratosInfo = isContrato || contrato?.tipo_objeto === 'Contrato';
  const isButtonClose = isOnlyView ? 'Fechar' : 'Cancelar';
  const disabledEdition = isOnlyView || situacaoDocumento === 'Encerrado';
  const title = nomeDocumento
    ? 'Editar'
    : isOnlyView
      ? 'Detalhamento'
      : 'Cadastrar contrato ou documento';

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth="sm"
      aria-labelledby="form-dialog-title"
      disableBackdropClick
      disableEscapeKeyDown>
      <Loading loading={loading} />
      <form onSubmit={handleSubmit(save)}>
        <DialogTitle id="form-dialog-title">{title}</DialogTitle>
        {open && (
          <DialogContent style={{ minHeight: '50vh' }}>
            {!loading && (
              <Grid container spacing={4} style={{ padding: '20px' }}>
                <Grid container spacing={2}>
                  <Grid item sm={12}>
                    <Field
                      name="tipo_objeto"
                      component={Select}
                      label="Tipo de objeto"
                      onChange={handleSelectChange}
                      disabled={hasContratoId}>
                      <MenuItem value="">
                        <em>Selecione</em>
                      </MenuItem>
                      {config.tipoObjeto.map((estado) => (
                        <MenuItem key={estado} value={estado}>
                          {estado}
                        </MenuItem>
                      ))}
                    </Field>
                  </Grid>
                  <Grid item sm={12}>
                    <Field
                      name="nome"
                      component={InputText}
                      label="Nome"
                      disabled={disabledEdition}
                    />
                  </Grid>
                  <Grid item sm={12}>
                    <FieldArray
                      name="cnpj_contratados"
                      component={cnpj_contratados}
                      beneficiarios={beneficiarios}
                      isContrato={isContrato || contrato?.tipo_objeto === 'Contrato'}
                      disabled={disabledEdition}
                    />
                  </Grid>
                  {showContratosInfo && (
                    <>
                      <Grid item sm={4}>
                        <Field
                          name="data_inicio"
                          component={DatePicker}
                          label="Ínicio da vigência"
                          disabled={disabledEdition}
                        />
                      </Grid>
                      <Grid item sm={4}>
                        <Field
                          name="data_fim"
                          component={DatePicker}
                          label="Fim da vigência"
                          disabled={disabledEdition}
                        />
                      </Grid>
                      <Grid item sm={4}>
                        <Field
                          name="data_alerta"
                          component={DatePicker}
                          label="Alerta rescisão/renovação"
                          disabled={disabledEdition}
                        />
                      </Grid>
                      <Grid item sm={12}>
                        <FieldArray name="emails" component={emails} disabled={disabledEdition} />
                      </Grid>
                    </>
                  )}
                  {contrato && (
                    <Grid item sm={12}>
                      <AnexoContratosDocumentos
                        onChange={handleAnexosChange}
                        open={openAnexos}
                        contratoId={contratoId}
                        anexo={contrato?.anexos}
                        disabled={disabledEdition}
                        handleClose={handleCloseAnexos}
                      />
                    </Grid>
                  )}
                  <Grid item sm={12}>
                    <Field
                      name="observacoes"
                      component={InputText}
                      label="Observações"
                      multiline={true}
                      disabled={isOnlyView}
                    />
                  </Grid>
                </Grid>
              </Grid>
            )}
          </DialogContent>
        )}
        <DialogActions style={{ display: 'flex', justifyContent: 'center' }}>
          <Button onClick={() => handleCancel(contrato, isOnlyView)} color="default">
            {isButtonClose}
          </Button>
          {!isOnlyView && (
            <Button type="submit" disabled={pristine || submitting} color="primary">
              Salvar
            </Button>
          )}
        </DialogActions>
      </form>
    </Dialog>
  );
};

const mapStateToProps = (state) => {
  const contrato = state.contratos.contrato;
  return {
    initialValues: contrato,
  };
};

DocumentoContratoForm.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    criarContrato,
    saveContrato,
    setContratoId,
    getContrato,
  })
)(
  reduxForm({
    form: 'documento',
    validate,
    enableReinitialize: true,
  })(withSnackbar(DocumentoContratoForm))
);
