import React, { useState } from 'react';
import {
  Button,
  FormControl,
  InputLabel,
  Input,
  Paper,
  CssBaseline,
  Typography,
  Snackbar,
  CircularProgress,
  Grid,
} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import { parcelaCompensatoria } from '../firebase/functions';
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import WarningIcon from '@material-ui/icons/Warning'


const theme = {
  main: {
    width: 'auto',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 16,
    maxWidth: '450px',
    borderRadius: '16px',
  },
  form: {
    width: '100%',
  },
  submit: {
    marginTop: 24,
    marginBottom: 20,
    backgroundColor: '#B2CC85'
  },
  backGorund: {
    zIndex: -1,
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    background:
      'url(./img/BG-parcela-compensatoria.png) no-repeat center center fixed',
  },
};

const ParcelaCompensatoriaPage = () => {
  const [nome, setNome] = useState('');
  const [error, setError] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [message, setMessage] = useState('')
  const [loading, setLoading] = useState(false);
  const [interessado, setInteressado] = useState('');

  const gerarMensagem = (registro) => {
    const { "FILIADA(O)?": filiada, "JÁ ESTÁ NA AÇÃO?": estaNaAcao } = registro;

    const mensagens = {
      firstMessage: <span>Você tem direito, já está incluído na ação e é filiado ao Sindilegis. Para acompanhar o andamento do processo, entre em contato pelo telefone <strong>(61) 3214-7300</strong> ou pelo e-mail <strong>juridico@sindilegis.org.br</strong> sempre que necessário.</span>,
      secondMessage: <span>Você tem direito à ação, mas não está incluído porque não é filiado ao Sindilegis. Para saber como participar, entre em contato com nosso atendimento pelo telefone <strong>(61) 3214-7300</strong> ou pelo e-mail <strong>juridico@sindilegis.org.br</strong> e fale com o Jurídico na Opção 1 da central. (Caso seja filiado a Auditar ou AsapTCU entre em contato com sua entidade).</span>,
      thirdMessage: <span>Você tem direito à ação e é filiado ao Sindilegis, mas ainda não está incluído. Para mais informações sobre sua adesão, entre em contato com nosso atendimento pelo telefone <strong>(61) 3214-7300</strong> ou pelo e-mail <strong>juridico@sindilegis.org.br</strong> e fale com o Jurídico na Opção 1 da central.  (Caso seja filiado a Auditar ou AsapTCU entre em contato com sua entidade).</span>,
      fourthMessage: <span>Você está na ação, mas sua filiação ao Sindilegis não está regularizada. Para garantir sua continuidade no processo, regularize sua situação entrando em contato com nosso atendimento pelo telefone <strong>(61) 3214-7300</strong> ou pelo e-mail <strong>juridico@sindilegis.org.br</strong> e falando com o Jurídico na Opção 1 da central.</span>,
      fifthMessage: <span>Você tem direito à ação. Para mais informações, entre em contato com nosso atendimento pelo telefone <strong>(61) 3214-7300</strong> ou pelo e-mail <strong>juridico@sindilegis.org.br</strong> e fale com o Jurídico na Opção 1 da central. (Caso seja filiado a Auditar ou AsapTCU entre em contato com sua entidade) .</span>
    };

    const conditions = [
      { check: estaNaAcao === "SIM" && filiada === 'SIM', key: "firstMessage", icon: 'success' },
      { check: estaNaAcao === 'NÃO' && filiada === 'NÃO', key: 'secondMessage' },
      { check: estaNaAcao === 'NÃO' && filiada === 'SIM', key: 'thirdMessage' },
      { check: estaNaAcao === 'SIM' && filiada === 'NÃO', key: 'fourthMessage' },
      { check: estaNaAcao === 'SIM' && filiada === 'FALECIDO', key: 'fifthMessage' },
    ];

    const resultado = conditions.find(({ check }) => check);

    if (!resultado) return null;

    return (
      <span style={{ display: "inline-flex", alignItems: "center", gap: "8px" }}>
        {resultado.icon === "success" ? (
          <CheckCircleIcon style={{ color: "green" }} />
        ) : (
          <WarningIcon style={{ color: "orange" }} />
        )}
        <span>{mensagens[resultado.key]}</span>
      </span>
    );
  };

  const gerarInteressado = (registro) => {
    const {
      "NOME DO SERVIDOR ": nome,
    } = registro

    return nome
  }

  const onSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    setMessage('');
    setInteressado('');
    parcelaCompensatoria(nome)
      .then((response) => {
        const result = response.data;
        if (result.sucesso) {
          setLoading(false);
          const mensagem = gerarMensagem(result.dados);
          setMessage(mensagem);
          setInteressado(gerarInteressado(result.dados));
          setError(null);
        } else {
          setLoading(false)
          setMessage(result.mensagem);
        }
      })
      .catch((error) => {
        console.error(error);
        setLoading(false)
        setError('Erro ao processar a solicitação, entre em contato com o suporte do sistema.');
        setOpenSnackbar(true);
      });
  };


  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const handleChange = (event) => {
    const value = event.target.value;
    setNome(value);
  };

  const isInvalid = nome === '';

  return (
    <main style={theme.main}>
      <CssBaseline />
      <Paper style={theme.paper}>
        <img
          src="/img/titulo-parcela-compensatoria.png"
          style={{ width: '150%', height: 'auto', }}
          alt="Sindilegis"
        />
        {!message && !loading && (
          <Typography style={{ textAlign: 'center', fontWeight: 'bold' }}>
            Pesquise pelo seu nome completo e sem abreviações:
          </Typography>
        )}
        {interessado && (
          <Typography color='inherit' style={{ textAlign: 'center' }}>
            {interessado}
          </Typography>
        )}
        {message && (
          <Typography>
            {message}
          </Typography>
        )}
        {loading ? (
          <Grid container style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <CircularProgress style={{ color: '#957188', margin: 5 }} />
            <Typography>
              Aguarde, estamos verificando o nome informado.
            </Typography>
          </Grid>
        ) :
          <form onSubmit={onSubmit} style={theme.form}>
            <FormControl margin="normal" required fullWidth>
              <InputLabel htmlFor="nome">Nome Completo</InputLabel>
              <Input
                id="nome"
                value={nome}
                onChange={handleChange}
                type="text"
                error={!!error}
                maxLength={14}
              />
            </FormControl>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              disabled={isInvalid}
              style={theme.submit}
            >
              <Typography variant="body2" style={{ color: 'white' }}>
                <strong>Pesquisar</strong>
              </Typography>
            </Button>
            <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
              <MuiAlert onClose={handleCloseSnackbar} severity="error">
                {error}
              </MuiAlert>
            </Snackbar>
          </form>
        }
      </Paper>
      <div style={theme.backGorund} />
    </main>
  );
};

export default ParcelaCompensatoriaPage;
