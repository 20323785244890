import React from 'react';
import _ from 'lodash';
import { Field, reduxForm, FieldArray } from 'redux-form';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import DatePicker from '../../commons/DatePicker';
import InputText from '../../commons/InputText';
import { withSnackbar } from 'notistack';
import styles from '../styles';
import {
  atualizarProcesso,
  criarProcessoJudicial,
  getAtendimentos,
  getEscritoriosAdvocacia,
} from '../../../actions/juridico';
import AsyncAutoCompleteSelect from '../../commons/AsyncAutoComplete';
import { getFiliados } from '../../../actions/filiado';
import { getObjetosProcesso } from '../../../actions/objetoProcesso';
import Switch from '../../commons/Switch';
import InputNumber from '../../commons/InputNumber';
import Select from '../../commons/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { Titulo, Orgaos } from './processosCommons';
import RadioButton from '../../commons/RadioButton';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import { onceGetEscritorioAdvocacia } from '../../../firebase/db';
import UploadArquivosProcesso from './uploadArquivosProcesso';
import Comentarios from '../comentarios';

class ProcessoJudicialForm extends React.Component {
  state = { anexos: [] };

  componentDidMount() {
    const { getEscritoriosAdvocacia, getAtendimentos, getObjetosProcesso, filiados, getFiliados } =
      this.props;
    getObjetosProcesso();
    getEscritoriosAdvocacia();
    getAtendimentos();

    if (!filiados || filiados.length === 0) {
      getFiliados();
    }
  }

  save(processoJudicial) {
    const { escritoriosAdvocacia, filiados, atualizarProcesso, criarProcessoJudicial } = this.props;
    const processoJudicialId = processoJudicial?.id;
    if (processoJudicialId) {
      atualizarProcesso(processoJudicial, filiados, escritoriosAdvocacia, () =>
        this.successHandler(true)
      );
    } else {
      criarProcessoJudicial(processoJudicial, filiados, escritoriosAdvocacia, () =>
        this.successHandler(true)
      );
    }
  }

  successHandler(reload) {
    const { handleClose, enqueueSnackbar, reset } = this.props;
    enqueueSnackbar('Processo judicial salvo com sucesso!', {
      variant: 'success',
    });
    reset();
    handleClose(reload);
  }

  cancelar() {
    const { reset, handleClose } = this.props;
    reset();
    handleClose();
  }

  errorHandler() {
    const { enqueueSnackbar } = this.props;
    enqueueSnackbar('Erro ao salvar interação.', {
      variant: 'error',
    });
  }

  render() {
    const {
      handleSubmit,
      pristine,
      invalid,
      submitting,
      filiados,
      atendimentos,
      authUser,
      processoJudicial,
      open,
      handleClose,
      processo,
      escritoriosAdvocacia,
      objetosProcesso,
    } = this.props;

    const filiadosIndeterminados = processoJudicial?.values?.filiadosIndeterminados;
    const relacionadoAtendimentos = processoJudicial?.values?.relacionadoAtendimentos;
    const movedBySindilegis = processoJudicial?.values?.movedBySindilegis
    const showFiliados = movedBySindilegis !== 'Sim'
    const showFiliadosIndeterminados = !filiadosIndeterminados && showFiliados

    const atendimentosComId = [];
    if (atendimentos) {
      const escritorios = Object.keys(atendimentos);
      escritorios.forEach((idEscritorio) => {
        const atendimentosEscritorio = atendimentos[idEscritorio];
        Object.keys(atendimentosEscritorio).forEach((idAtendimento) => {
          const atendimentoEscritorio = atendimentosEscritorio[idAtendimento];
          if (atendimentoEscritorio.escritorio == null) {
            //Legado
            onceGetEscritorioAdvocacia(idEscritorio).then((e) => {
              const escritorioAdvocacia = e.val();
              escritorioAdvocacia.id = idEscritorio;
              atendimentosEscritorio.escritorio = escritorioAdvocacia;
              atendimentosComId.push({
                id: idAtendimento,
                ...atendimentosEscritorio[idAtendimento],
              });
            });
          } else {
            atendimentosComId.push({
              id: idAtendimento,
              ...atendimentosEscritorio[idAtendimento],
            });
          }
        });
      });
    }

    const prefixTitle = processo ? 'Editar' : 'Novo';
    const orderedObjetosProcesso = objetosProcesso.sort((a, b) => a.descricao.localeCompare(b.descricao))

    return (
      <Dialog
        open={open}
        onClose={handleClose.bind(this, null)}
        fullWidth
        maxWidth="md"
        aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">{prefixTitle} processo judicial</DialogTitle>
        <form onSubmit={handleSubmit((values) => this.save(values))}>
          <DialogContent>
            <Grid
              container
              spacing={2}
              hidden={false}
              alignItems="flex-start"
              justifyContent="flex-start">
              <Grid item sm={4}>
                <Field
                  name="relacionadoAtendimentos"
                  component={RadioButton}
                  label="Relacionado a atendimentos?">
                  <FormControlLabel value="Sim" control={<Radio />} label="Sim" />
                  <FormControlLabel value="Não" control={<Radio />} label="Não" />
                </Field>
              </Grid>
              {relacionadoAtendimentos === 'Sim' && (
                <Grid item sm={4}>
                  <Field
                    defaultOptions={[]}
                    isMulti={true}
                    loadOptions={(input) => {
                      if (input.length < 3) {
                        return Promise.resolve([]);
                      }
                      return Promise.resolve(
                        atendimentosComId
                          .filter((a) => a.numero && a.numero.includes(input))
                          .map((a) => ({ label: a.numero, value: a.id }))
                          .slice(0, 100)
                      );
                    }}
                    name="atendimentos"
                    component={AsyncAutoCompleteSelect}
                    label="Atendimentos"
                  />
                </Grid>
              )}
              <Grid item sm={4}>
                <Field
                  name="movedBySindilegis"
                  component={RadioButton}
                  label="Processo movido pelo Sindilegis?">
                  <FormControlLabel value="Sim" control={<Radio />} label="Sim" />
                  <FormControlLabel value="Não" control={<Radio />} label="Não" />
                </Field>
              </Grid>
            </Grid>
            <Grid
              container
              spacing={2}
              hidden={false}
              alignItems="flex-start"
              justifyContent="flex-start">
              <Grid item sm={3}>
                <Field name="natureza" component={Select} label="Natureza">
                  <MenuItem value="">
                    <em>Selecione</em>
                  </MenuItem>
                  {['Profissional', 'Particular'].map((t) => (
                    <MenuItem key={t} value={t}>
                      {t}
                    </MenuItem>
                  ))}
                </Field>
              </Grid>
              <Grid item sm={3}>
                <Field name="tags" component={InputText} multiline={false} label="Tags" />
              </Grid>
              <Grid item sm={3}>
                <Field name="dataProtocolo" component={DatePicker} label="Data de protocolo" />
              </Grid>
              <Grid item sm={3}>
                <Field
                  name="numeroProcesso"
                  component={InputText}
                  multiline={false}
                  label="Número do processo"
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} hidden={false} alignItems="center" justifyContent="center">
              <Grid item sm={4}>
                <Field
                  name="tribunalOrgao"
                  component={InputText}
                  multiline={false}
                  label="Tribunal/Orgão"
                />
              </Grid>
              <Grid item sm={4}>
                <Field
                  name="linkProcesso"
                  component={InputText}
                  multiline={false}
                  label="Link para processo"
                />
              </Grid>
              <Grid item sm={4}>
                <Field name="valorCausa" component={InputNumber} label="Valor" />
              </Grid>
            </Grid>
            <Grid container spacing={2} hidden={false} alignItems="center" justifyContent="center">
              <Grid item sm={12}>
                <Field name="escritorio" component={Select} label="Escritório">
                  <MenuItem value="">
                    <em>Selecione</em>
                  </MenuItem>
                  {escritoriosAdvocacia.map((t) => (
                    <MenuItem key={t.nome} value={t.id}>
                      {t.nome}
                    </MenuItem>
                  ))}
                </Field>
              </Grid>
            </Grid>
            <Grid container spacing={2} hidden={false} alignItems="center" justifyContent="center">
              <Grid item sm={12}>
                <Field name="objetoProcesso" component={Select} label="Objeto do processo">
                  <MenuItem value="">
                    <em>Selecione</em>
                  </MenuItem>
                  {orderedObjetosProcesso.map((t) => (
                    <MenuItem
                      key={t.descricao}
                      value={t.descricao}>
                      {t?.descricao}
                    </MenuItem>
                  ))}
                </Field>
              </Grid>
            </Grid>
            <Grid
              container
              spacing={2}
              hidden={false}
              alignItems="flex-start"
              justifyContent="flex-start">
              <Grid
                container
                spacing={2}
                hidden={false}
                alignItems="flex-start"
                justifyContent="flex-start">
                <Grid item sm={12}>
                  <Field
                    name="pessoasTemDireito"
                    component={InputText}
                    multiline={false}
                    label="Quais pessoas tem direito?"
                  />
                </Grid>
              </Grid>
              <Grid
                container
                spacing={2}
                hidden={false}
                alignItems="flex-start"
                justifyContent="flex-start">
                <Grid item sm={3}>
                  <Field name="publicarPortalSindilegis" component={Switch} label="Ação Coletiva" />
                </Grid>
                <Titulo />
                {showFiliados && (
                  <Grid item sm={3}>
                    <Field
                      name="filiadosIndeterminados"
                      component={Switch}
                      label="Filiados indeterminados"
                    />
                  </Grid>
                )}
                <Grid item sm={3}>
                  <Field name="haveraComunicacao" component={Switch} label="Haverá comunicação" />
                </Grid>
                <Orgaos />
              </Grid>
            </Grid>
            {showFiliadosIndeterminados && (
              <Grid
                container
                spacing={2}
                hidden={false}
                alignItems="flex-start"
                justifyContent="flex-start">
                <Grid item sm={12}>
                  <Field
                    defaultOptions={[]}
                    isMulti={true}
                    loadOptions={(input) => {
                      if (input.length < 3) {
                        return Promise.resolve([]);
                      }
                      return Promise.resolve(
                        filiados
                          .filter(
                            (f) =>
                              f.nome_completo &&
                              f.nome_completo.toLowerCase().includes(input.toLowerCase())
                          )
                          .map((f) => ({ label: `${f.nome_completo} - ${f.cpf}`, value: f.id }))
                          .slice(0, 100)
                      );
                    }}
                    name="filiados"
                    component={AsyncAutoCompleteSelect}
                    label="Filiados interessados"
                  />
                </Grid>
              </Grid>
            )}
            <Grid
              container
              spacing={2}
              hidden={false}
              alignItems="flex-start"
              justifyContent="flex-start">
              <Grid item sm={12}>
                <UploadArquivosProcesso
                  onChange={(anexos) => {
                    this.setState({ anexos });
                  }}
                />
              </Grid>
              <Grid item sm={12}>
                <Typography variant="h6">Comentários registrados</Typography>
                <FieldArray name="comentarios" component={Comentarios} email={authUser?.email} />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.cancelar.bind(this)} color="default">
              Cancelar
            </Button>
            <Button
              variant="contained"
              type="submit"
              disabled={pristine || submitting || invalid}
              color="primary">
              Salvar
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    );
  }
}

ProcessoJudicialForm.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = ({
  form: { processoJudicial },
  filiado: { filiado, filiadoId, filiados },
  juridico: { escritoriosAdvocacia, processo, atendimentos },
  objetoProcesso: { objetosProcesso },
}) => ({
  initialValues: processo
    ? {
      ...processo,
      escritorio: processo.escritorio.id,
      filiados: processo.filiados
        ? processo.filiados.map((f) => ({
          label: f.nome_completo,
          value: f.id,
        }))
        : undefined,
    }
    : undefined,
  processoJudicial,
  escritoriosAdvocacia,
  atendimentos,
  filiado,
  filiadoId,
  filiados,
  processo,
  objetosProcesso,
});

const validate = (values) => {
  const errors = {};
  const requiredFields = [
    'tribunalOrgao',
    'dataProtocolo',
    'objetoProcesso',
    'linkProcesso',
    'numeroProcesso',
    'escritorio',
    'valorCausa',
    'filiados',
  ];

  if (values.publicarPortalSindilegis) {
    requiredFields.push('titulo');
  }

  if (values['filiadosIndeterminados'] && _.isEmpty(values['orgaos'])) {
    errors['orgaos'] = ' Obrigatório';
  }

  requiredFields.forEach((field) => {
    if (!values[field] || (Array.isArray(values[field]) && values[field].length === 0)) {
      errors[field] = ' Obrigatório';
    }
  });

  return errors;
};

export default compose(
  connect(mapStateToProps, {
    atualizarProcesso,
    criarProcessoJudicial,
    getEscritoriosAdvocacia,
    getFiliados,
    getAtendimentos,
    getObjetosProcesso,
  }),
  withStyles(styles)
)(
  reduxForm({
    form: 'processoJudicial',
    validate,
    enableReinitialize: true,
  })(withSnackbar(ProcessoJudicialForm))
);
