import React from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';
import styles from './styles';
import { comunicarPorEmail, setFiliadosAComunicar } from '../../actions/comunicacao';
import 'draft-js/dist/Draft.css';
import { Editor } from 'react-draft-wysiwyg';
import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import Typography from '@material-ui/core/Typography';
import { convertToRaw, EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { Field, reduxForm } from 'redux-form';
import InputText from '../commons/InputText';
import Select from '../commons/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { remetentes } from './config';

class ComunicacaoDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mensagem: EditorState.createEmpty(),
      enviando: false,
      buttonMessage: 'Enviar',
    };
  }

  onChange = (mensagem) => {
    this.setState({ mensagem });
  };

  enviar(values) {
    this.setState({ ...this.state, enviando: true, buttonMessage: 'Enviando...' });

    const { titulo, remetente, tipoComunicacao } = values;
    const { comunicarPorEmail, filiadosAComunicar } = this.props;
    const { mensagem } = this.state;
    if (tipoComunicacao === 'email') {
      const mensagemHtml = draftToHtml(convertToRaw(mensagem.getCurrentContent()));
      comunicarPorEmail(
        remetente,
        filiadosAComunicar,
        titulo,
        mensagemHtml,
        this.successHandler.bind(this),
        this.failureHandler.bind(this)
      );
    }
  }

  successHandler() {
    const { handleClose, enqueueSnackbar, reset } = this.props;
    enqueueSnackbar('Comunicação enviada com sucesso!', {
      variant: 'success',
    });
    reset();
    this.setState({
      ...this.state,
      mensagem: EditorState.createEmpty(),
      enviando: false,
      buttonMessage: 'Enviar',
    });
    handleClose(false);
  }

  failureHandler() {
    const { enqueueSnackbar } = this.props;
    this.setState({ ...this.state, enviando: false, buttonMessage: 'Enviar' });

    enqueueSnackbar('Falha ao enviar comunicação, tente novamente', {
      variant: 'error',
    });
  }

  cancelar() {
    const { setFiliadosAComunicar, reset, handleClose } = this.props;
    setFiliadosAComunicar([]);
    reset();
    this.setState({ mensagem: EditorState.createEmpty() });
    handleClose();
  }

  errorHandler() {
    const { enqueueSnackbar } = this.props;
    enqueueSnackbar('Erro ao enviar comunicação.', {
      variant: 'error',
    });
  }

  render() {
    const {
      handleSubmit,
      pristine,
      invalid,
      submitting,
      filiadosAComunicar,
      comunicacao,
      open,
      handleClose,
    } = this.props;
    const { mensagem, enviando, buttonMessage } = this.state;

    const mensagemHtml = draftToHtml(convertToRaw(mensagem.getCurrentContent()));

    const tipoComunicacao = comunicacao && comunicacao.values && comunicacao.values.tipoComunicacao;

    return (
      <Dialog
        open={open}
        onClose={handleClose.bind(this, null)}
        fullWidth
        maxWidth="md"
        aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Comunicar</DialogTitle>
        <form onSubmit={handleSubmit(this.enviar.bind(this))}>
          <DialogContent>
            <Grid container spacing={2} hidden={false} alignItems="center" justifyContent="center">
              <Grid item sm={12}>
                <Typography>
                  {' '}
                  Filiado(s):{' '}
                  {filiadosAComunicar.length <= 20
                    ? filiadosAComunicar.map((f) => f.nome_completo).join(', ')
                    : `${filiadosAComunicar
                        .slice(0, 20)
                        .map((f) => f.nome_completo)
                        .join(', ')} e mais ${filiadosAComunicar.length - 20} filiado(s)`}
                </Typography>
                <hr />
              </Grid>
              <Grid item sm={12}>
                <Field name="tipoComunicacao" component={Select} label="Tipo de comunicação">
                  <MenuItem value="email">
                    <em>E-mail</em>
                  </MenuItem>
                </Field>
              </Grid>
              {tipoComunicacao === 'email' && this.camposEmail()}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.cancelar.bind(this)} color="default">
              Cancelar
            </Button>
            <Button
              variant="contained"
              disabled={
                (tipoComunicacao === 'email' && mensagemHtml.trim() === '<p></p>') ||
                pristine ||
                submitting ||
                invalid ||
                enviando
              }
              color="primary"
              type="submit">
              {buttonMessage}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    );
  }

  camposEmail() {
    const { mensagem } = this.state;
    return (
      <React.Fragment>
        <Grid item sm={12}>
          <Field name="remetente" component={Select} label="Remetente">
            {remetentes.map((remetente) => {
              return (
                <MenuItem value={remetente}>
                  <em>{remetente}</em>
                </MenuItem>
              );
            })}
          </Field>
        </Grid>
        <Grid item sm={12}>
          <Field name="titulo" component={InputText} multiline={false} label="Título" />
        </Grid>
        <Grid item sm={12}>
          <div className="editor">
            <Editor
              editorState={mensagem}
              wrapperClassName="rich-editor demo-wrapper custom-rich-text"
              editorClassName="demo-editor"
              onEditorStateChange={this.onChange}
              toolbar={{
                options: ['image', 'inline', 'fontSize', 'list', 'textAlign', 'history'],
              }}
            />
          </div>
        </Grid>
      </React.Fragment>
    );
  }
}

const validate = (values) => {
  const errors = {};
  const requiredFields = ['titulo', 'remetente', 'template'];
  requiredFields.forEach((field) => {
    if (!values[field] || (Array.isArray(values[field]) && values[field].length === 0)) {
      errors[field] = ' Obrigatório';
    }
  });

  return errors;
};

ComunicacaoDialog.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = ({ form: { comunicacao }, comunicacao: { filiadosAComunicar } }) => ({
  filiadosAComunicar,
  comunicacao,
});

export default compose(
  connect(mapStateToProps, { comunicarPorEmail, setFiliadosAComunicar }),
  withStyles(styles)
)(
  reduxForm({
    form: 'comunicacao',
    validate,
    enableReinitialize: true,
  })(withSnackbar(ComunicacaoDialog))
);
